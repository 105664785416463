import React from "react";
import SEO from "../../components/Common/SEO";
import LandingHowWork from "../../components/Landing/LandingHowWork";
import LandingIntro from "../../components/Landing/LandingIntro";
import LandingPlatform from "../../components/Landing/LandingPlatform";
import LandingTrusted from "../../components/Landing/LandingTrusted";
import PageHeader from "../../components/PageHeader";
import "./landing.scss";

const LandingPage = () => {
  return (
    <React.Fragment>
      <SEO title="Explore | 6ix Events" />

      <div className="events-page-landing events6ix-layout-page">
        <PageHeader />
        <div className="events6ix-layout-page-content">
          <LandingIntro />
          <LandingHowWork />
          <LandingTrusted />
          <LandingPlatform />
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
