import React from "react";
import Company from "../components/Company";
import PageHeader from "../components/PageHeader";

const CompanyPage = () => {
  return (
    <div className="events-page-company events6ix-layout-page">
      <PageHeader type="overview" />
      <div className="events6ix-layout-page-content">
        <Company />
      </div>
    </div>
  );
};

export default CompanyPage;
