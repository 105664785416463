import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  FormControl,
  Menu,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { Box, Flex, Input, Label } from "@100mslive/roomkit-react";
import { UserIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import AccountMemberManageRole from "./AccountMemberManageRole";
import AccountMemberProfile from "./AccountMemberProfile";
import AccountMemberRemoveAccess from "./AccountMemberRemoveAccess";
import { SESSION_AUTH } from "../../../common/auth";
import { accountTeamRoleList, isValidEmail } from "../../../common/utils";
import { ACCOUNT_SERVICES } from "../../../services";
import { GET_ENCRYPT_DATA } from "../../../common/constants";

const AccountMemberSettings = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("team");
  const [userData, setUserData] = useState(null);
  const [dataFetched, setDataFetached] = useState();
  const [loading, setLoading] = useState(false);
  const [manageRole, setManageRole] = useState(false);
  const [removeAccess, setRemoveAccess] = useState(false);
  const [profileAccess, setProfileAccess] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const defaultFields = {
    email: "",
    role: "member",
  };
  const [inviteMemberList, setInviteMemberList] = useState(defaultFields);

  const [membersList, setMembersList] = useState([]);

  const [isEditAccess, setIsEditAccess] = useState(false);

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setUserData(isUserSession);
      setDataFetached(false);
      setSelectedMember("");
      setProfileAccess(false);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetached(true);
    try {
      setLoading(true);
      ACCOUNT_SERVICES.getMembers({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setMembersList(data?.data?.list);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isAccountOwner ||
        userData?.org?.isOwner ||
        userData?.org?.isAdmin
        ? true
        : false
    );
    return () => null;
  }, [userData]);

  const handleTeamTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMemberChangeRow = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedMember(item);
  };
  const closeMemberChangeRow = () => {
    setAnchorEl(null);
  };
  const memberChangeRow = () => {
    setAnchorEl(null);
    setManageRole(true);
  };
  const memberEditProfile = () => {
    setAnchorEl(null);
    setProfileAccess(true);
  };
  const memberRemoveAccess = () => {
    setAnchorEl(null);
    setRemoveAccess(true);
  };

  const addMember = () => {
    if (
      !inviteMemberList?.email ||
      (inviteMemberList?.email && !isValidEmail(inviteMemberList?.email))
    ) {
      ToastManager.addToast({ title: "Enter member email address" });
    } else if (!inviteMemberList?.role) {
      ToastManager.addToast({ title: "Select member role" });
    } else {
      try {
        setLoading(true);
        ACCOUNT_SERVICES.addMember({
          userId: userData.userId,
          org: userData?.org?.id,
          email: inviteMemberList?.email,
          role: inviteMemberList?.role,
          code: encodeURIComponent(GET_ENCRYPT_DATA(userData?.org?.id)),
        })
          .then(data => {
            if (data.code === 200) {
              setInviteMemberList(defaultFields);
              setDataFetached(false);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            }
            if (data.message) {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(error => {
            ToastManager.addToast({ title: error.message });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        ToastManager.addToast({ title: error.message });
      }
    }
  };

  return (
    <>
      {loading && <FullPageProgress fixed={true} />}
      <div className="events-page-account-setting-content">
        <div className="events-page-account-setting-content-header">
          <div className="events-page-account-setting-content-header-title">
            Members
          </div>
          {isEditAccess && (
            <div className="events-page-account-setting-content-header-text">
              Manage and add Team Members
            </div>
          )}
        </div>

        {isEditAccess && (
          <div className="events-page-account-setting-content-row">
            <Box className="events-page-account-setting-content-box">
              <div className="events-page-account-setting-content-box-title">
                Add new members
              </div>

              <div className="events-page-account-setting-content-box-line"></div>
              <Flex
                align="center"
                className="events-page-account-setting-content-box-row"
                css={{ gap: "20px" }}
              >
                <FormControl className="events-page-account-setting-content-box-control">
                  <Label
                    htmlFor="invite-email"
                    className="events-page-account-setting-content-box-lable"
                  >
                    Enter new members by email address
                  </Label>
                  <Input
                    id="invite-email"
                    name="email"
                    type="email"
                    className="custom-form-input"
                    placeholder="Enter an email address"
                    value={inviteMemberList?.email}
                    onChange={e =>
                      setInviteMemberList({
                        ...inviteMemberList,
                        email: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <FormControl className="events-page-account-setting-content-box-control">
                  <Label
                    htmlFor="invite-role"
                    className="events-page-account-setting-content-box-lable"
                  >
                    Role
                  </Label>
                  <Select
                    id="invite-role"
                    className="custom-select"
                    sx={{
                      height: "45px",
                      background: "#131313",
                      borderRadius: "8px",
                      width: "auto",
                    }}
                    value={inviteMemberList?.role}
                    onChange={e =>
                      setInviteMemberList({
                        ...inviteMemberList,
                        role: e.target.value,
                      })
                    }
                    displayEmpty
                  >
                    {Object.keys(accountTeamRoleList).map((item, index) => (
                      <MenuItem
                        key={index}
                        value={accountTeamRoleList[item].value}
                      >
                        {accountTeamRoleList[item].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <div className="events-page-account-setting-content-box-line"></div>

              <Flex
                align="center"
                className="events-page-account-setting-content-box-row"
              >
                <Box
                  css={{
                    flex: "1 1 0",
                  }}
                  className="events-page-account-setting-content-box-text"
                >
                  <Flex
                    css={{
                      alignItems: "center",
                    }}
                  >
                    Owners possess the ability to manage teams, billing, reset
                    the organization, and events. Admins have the ability to
                    manage teams, billing, and events. Members have the ability
                    to manage events.
                  </Flex>
                </Box>

                <Button
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                  type="button"
                  onClick={addMember}
                >
                  ADD
                </Button>
              </Flex>
            </Box>
          </div>
        )}

        <div className="events-page-account-setting-content-row">
          <Tabs
            value={selectedTab}
            onChange={handleTeamTab}
            aria-label="basic tabs example"
            className="events-page-account-setting-content-tabs"
          >
            <Tab
              label="Team Members"
              value="team"
              id="members-tab-team"
              aria-controls="members-tab-team"
            />
          </Tabs>

          <Box className="events-page-account-setting-content-member">
            <TableContainer className="events-page-account-setting-content-box">
              <Table sx={{ minWidth: 550 }} aria-label="members table">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}>S.no</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {membersList.map((row, key) => (
                    <TableRow
                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell width={20} component="th">
                        {key + 1}
                      </TableCell>
                      <TableCell width={50}>
                        <div className="events-page-account-setting-content-member-profileImg">
                          {row?.profile.profileImage ? (
                            <img
                              src={row?.profile.profileImage}
                              alt="profile"
                            />
                          ) : (
                            <UserIcon />
                          )}
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="events-page-account-setting-content-member-info">
                          <div className="events-page-account-setting-content-member-name">
                            {row?.profile?.name}
                          </div>
                          <div className="events-page-account-setting-content-member-text">
                            {row?.profile?.email}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell component="th">
                        <div className="events-page-account-setting-content-member-position">
                          {accountTeamRoleList?.[row.role]?.name || "-"}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {row.id &&
                          !row.isAccountOwner &&
                          row.userId !== userData.userId &&
                          isEditAccess && (
                            <div>
                              <Button
                                id={`team-member-dropdown-button-${row.id}`}
                                aria-controls={
                                  open
                                    ? `team-member-dropdown-menu-${row.id}`
                                    : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={e => openMemberChangeRow(e, row)}
                              >
                                <MoreHorizIcon />
                              </Button>
                              <Menu
                                id={`team-member-dropdown-menu-${row.id}`}
                                aria-labelledby={`team-member-dropdown-menu-${row.id}`}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={closeMemberChangeRow}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem onClick={memberChangeRow}>
                                  Manage Role
                                </MenuItem>
                                <MenuItem onClick={memberEditProfile}>
                                  Edit Profile
                                </MenuItem>
                                <MenuItem onClick={memberRemoveAccess}>
                                  Remove Access
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
      {manageRole && selectedMember && (
        <AccountMemberManageRole
          open={manageRole ? true : false}
          onClose={() => {
            setManageRole(false);
          }}
          member={selectedMember}
          onUpdate={() => {
            setSelectedMember("");
            setDataFetached(false);
          }}
        />
      )}
      {removeAccess && selectedMember && (
        <AccountMemberRemoveAccess
          open={removeAccess ? true : false}
          onClose={() => {
            setRemoveAccess(false);
          }}
          member={selectedMember}
          onUpdate={() => {
            setDataFetached(false);
            setSelectedMember("");
          }}
        />
      )}
      {profileAccess && selectedMember && (
        <AccountMemberProfile
          open={setProfileAccess ? true : false}
          onClose={() => {
            setProfileAccess(false);
          }}
          member={selectedMember}
          onUpdate={() => {
            setDataFetached(false);
            setSelectedMember("");
          }}
        />
      )}
    </>
  );
};

export default AccountMemberSettings;
