import React from "react";
import EventFooterAccess from "./EventFooterAccess";
import EventFooterBottom from "./EventFooterBottom";
import EventFooterContent from "./EventFooterContent";
import EventFooterDivider from "./EventFooterDivider";
import EventFooterMenu from "./EventFooterMenu";
import EventFooterTop from "./EventFooterTop";
import "./style.scss";

const EventFooter = () => {
  return (
    <div className="events6ix-layout-footer">
      <EventFooterTop />
      <EventFooterDivider />
      <EventFooterAccess />
      <EventFooterDivider />
      <EventFooterMenu />
      <EventFooterDivider />
      <EventFooterContent />
      <EventFooterBottom />
    </div>
  );
};

export default EventFooter;
