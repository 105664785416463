import React from "react";
import SEO from "../components/Common/SEO";
import Explore from "../components/Explore/Explore";
import PageHeader from "../components/PageHeader";

export function ExplorePage(props) {
  return (
    <React.Fragment>
      <SEO title="Explore | 6ix Events" />
      <div className="events-page-explore events6ix-layout-page">
        <PageHeader />
        <div className="events6ix-layout-page-content">
          <Explore />
        </div>
      </div>
    </React.Fragment>
  );
}
