import React, { useState } from "react";
import {
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box, Flex, Input, Label, Switch } from "@100mslive/roomkit-react";
import { InfoIcon, MicIcon, VideoIcon } from "../../icons";
import ConfirmDialog from "../Common/ConfirmDialog";
import { EVENT_TYPE, ROOM_TYPE } from "../../common/constants";

const EventOptions = props => {
  const {
    userData,
    roomType,
    setRoomType,
    eventType,
    setEventType,
    disableSurvey,
    setDisableSurvey,
    eventChatQA,
    setEventChatQA,
    eventAddWidget,
    setEventAddWidget,
    eventWidget,
    setEventWidget,
    currentStep,
    setEventCalendlyLink,
    eventCalendlyLink,
    eventCalendlyLinkAdd,
    setEventCalendlyLinkAdd,
    orgAccountList,
  } = props;
  const [confirmRoom, setConfirmRoom] = useState("");
  const [selectCalOrg, setSelectCalOrg] = useState("");

  const onChangeRoomType = item => {
    if (item.confirm) {
      setConfirmRoom(item.value);
    } else {
      setRoomType(item.value);
    }
  };

  const onAcceptRoomType = () => {
    setRoomType(confirmRoom);
    setConfirmRoom("");
  };

  const changeOrgCal = value => {
    setSelectCalOrg(value);
    if (value === "custom") {
      setEventCalendlyLink("");
    } else {
      setEventCalendlyLink(value);
    }
  };
  return (
    <>
      <div className="home-page-layout-content-option">
        <Flex
          direction="column"
          css={{
            py: "$4",
            w: "100%",
            gap: "$2",
          }}
        >
          <Label className="home-page-layout-content-option-label">
            Visibility
            <Tooltip
              placement="top"
              arrow
              title="Visibility: The event will be listed in the explore page if the event is public"
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Label>
          <div className="eventsToggleGroup">
            {Object.keys(EVENT_TYPE)?.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`eventsToggleGroup-btn ${
                    eventType === EVENT_TYPE?.[item]?.value ? "active" : ""
                  }`}
                  onClick={() => {
                    if (currentStep === "event") {
                      setEventType(EVENT_TYPE?.[item]?.value);
                    }
                  }}
                >
                  {EVENT_TYPE?.[item]?.name}
                </div>
              );
            })}
          </div>
        </Flex>
        {userData ? (
          <Flex
            direction="column"
            css={{
              py: "$4",
              w: "100%",
              gap: "$2",
            }}
          >
            <Label className="home-page-layout-content-option-label">
              Room Type
              <Tooltip
                placement="top"
                arrow
                title="Select the number of people the room can accommodate"
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Label>
            <div className="eventsToggleGroup">
              {Object.keys(ROOM_TYPE)?.map((item, key) => {
                return (
                  <Tooltip
                    key={key}
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#000",
                          border: "1px solid #2B2B2B",
                        },
                      },
                    }}
                    title={
                      <Box className="eventsToggleGroup-btn-room-tooltip">
                        <Flex className="eventsToggleGroup-btn-room-tooltip-title">
                          {ROOM_TYPE?.[item]?.text}
                        </Flex>
                        <Flex className="eventsToggleGroup-btn-room-tooltip-option">
                          <MicIcon on={ROOM_TYPE?.[item]?.attendes?.mic} /> Mics
                        </Flex>
                        <Flex className="eventsToggleGroup-btn-room-tooltip-option">
                          <VideoIcon on={ROOM_TYPE?.[item]?.attendes?.mic} />{" "}
                          Cameras
                        </Flex>
                      </Box>
                    }
                  >
                    <div
                      className={`eventsToggleGroup-btn  ${
                        roomType === ROOM_TYPE?.[item]?.value ? "active" : ""
                      }`}
                      onClick={() => {
                        if (
                          currentStep === "event" &&
                          ROOM_TYPE?.[item]?.value !== roomType
                        ) {
                          onChangeRoomType(ROOM_TYPE?.[item]);
                        }
                      }}
                    >
                      {ROOM_TYPE?.[item]?.name}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </Flex>
        ) : null}
        <Flex
          direction="column"
          css={{
            py: "$4",
            gap: "$2",
          }}
        >
          <Label className="home-page-layout-content-option-label">
            Survey questionnaire for attendees
            <Tooltip
              placement="top"
              arrow
              title="Survey Questions: If enabled, we will ask investors various questions about themselves such as investor type, shareholder status, etc."
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Label>
          <div className="eventsToggleGroup">
            <div
              className={`eventsToggleGroup-btn ${
                !disableSurvey ? "active" : ""
              }`}
              onClick={() => {
                if (currentStep === "event") {
                  setDisableSurvey(false);
                }
              }}
            >
              Enabled
            </div>
            <div
              className={`eventsToggleGroup-btn ${
                disableSurvey ? "active" : ""
              }`}
              onClick={() => {
                if (currentStep === "event") {
                  setDisableSurvey(true);
                }
              }}
            >
              Disabled
            </div>
          </div>
        </Flex>
        {userData ? (
          <>
            <Flex
              direction="column"
              css={{
                mt: "$2",
                py: "$4",
                w: "100%",
                gap: "$2",
                alignItems: "flex-start",
              }}
            >
              <Label className="home-page-layout-content-option-label">
                Chat
                <Tooltip
                  placement="top"
                  arrow
                  title="Chat: If enabled, users will be able to use the live chat inside the event"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Label>

              <div className="eventsToggleGroup">
                <div
                  className={`eventsToggleGroup-btn ${
                    eventChatQA ? "active" : ""
                  }`}
                  onClick={() => {
                    if (currentStep === "event") {
                      setEventChatQA(true);
                    }
                  }}
                >
                  Enabled
                </div>
                <div
                  className={`eventsToggleGroup-btn ${
                    !eventChatQA ? "active" : ""
                  }`}
                  onClick={() => {
                    if (currentStep === "event") {
                      setEventChatQA(false);
                    }
                  }}
                >
                  Disabled
                </div>
              </div>
            </Flex>

            <Flex
              direction="column"
              css={{
                mt: "$2",
                py: "$4",
                w: "100%",
                gap: "$8",
                alignItems: "flex-start",
              }}
            >
              <Label
                className="home-page-layout-content-option-label"
                css={{
                  cursor: "pointer",
                  gap: "$8",
                }}
              >
                <Switch
                  checked={eventCalendlyLinkAdd}
                  onCheckedChange={() => {
                    if (currentStep === "event") {
                      setEventCalendlyLinkAdd(!eventCalendlyLinkAdd);
                    }
                  }}
                />
                Add Calendar Link
                <Tooltip
                  placement="top"
                  arrow
                  title="Add Calendar: Select whether you want to include your calendar link so attendees can optionally book a meeting with you"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Label>
              {eventCalendlyLinkAdd ? (
                <Flex
                  //direction="column"
                  css={{
                    w: "100%",
                    gap: "$8",
                  }}
                >
                  {orgAccountList && (
                    <Box
                      css={{
                        minWidth: "50%",
                      }}
                    >
                      <label
                        htmlFor="event-calendar-org"
                        className="custom-form-label"
                      >
                        Organization calendar
                      </label>
                      <Select
                        id="event-calendar-org"
                        name="eventcalendarorg"
                        className="custom-select w-100"
                        onChange={e => {
                          changeOrgCal(e.target.value);
                        }}
                        value={selectCalOrg}
                        displayEmpty
                        readOnly={currentStep !== "event"}
                        placeholder="Select"
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {orgAccountList
                          .filter(item => item?.orgCalLink)
                          .map((item, key) => {
                            return (
                              <MenuItem key={key} value={item?.orgCalLink}>
                                {item?.orgName}
                              </MenuItem>
                            );
                          })}
                        <MenuItem value="custom">Custom</MenuItem>
                      </Select>
                    </Box>
                  )}
                  {selectCalOrg || !orgAccountList ? (
                    <Box
                      css={{
                        minWidth: "50%",
                      }}
                    >
                      <label
                        htmlFor="event-calendar-org-url"
                        className="custom-form-label"
                      >
                        URL
                      </label>
                      <Input
                        id="event-calendar-org-url"
                        value={eventCalendlyLink}
                        readOnly={
                          currentStep !== "event" ||
                          (orgAccountList && selectCalOrg !== "custom")
                        }
                        placeholder="Enter calendar link"
                        onChange={e => {
                          if (currentStep === "event") {
                            setEventCalendlyLink(e.target.value);
                          }
                        }}
                        className="custom-form-input custom-form-input-sm"
                      />
                    </Box>
                  ) : null}
                </Flex>
              ) : null}
            </Flex>
            <Flex
              css={{
                py: "$4",
                w: "100%",
                gap: "$8",
              }}
              direction="column"
            >
              <Label
                className="home-page-layout-content-option-label"
                css={{
                  cursor: "pointer",
                  gap: "$8",
                }}
              >
                <Switch
                  checked={eventAddWidget}
                  onCheckedChange={() => {
                    if (currentStep === "event") {
                      setEventAddWidget(!eventAddWidget);
                    }
                  }}
                />
                Add Widget
                <Tooltip
                  placement="top"
                  arrow
                  title="Add Widget: Select whether you want to have a custom panel where you can embed 3rd party content such as a PDF download, 3D virtual reality presentation, Web3 Wallet or anything else."
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Label>
              {eventAddWidget ? (
                <div className="home-page-layout-content-box-des">
                  <TextareaAutosize
                    value={eventWidget}
                    readOnly={currentStep !== "event"}
                    onChange={e => {
                      if (currentStep === "event") {
                        setEventWidget(e.target.value);
                      }
                    }}
                    className="custom-form-input custom-form-input-sm"
                    minRows={2}
                    maxRows={3}
                  />
                </div>
              ) : null}
            </Flex>
          </>
        ) : null}
      </div>
      {confirmRoom && (
        <ConfirmDialog
          open={confirmRoom ? true : false}
          data={{
            title: "Are you sure?",
            text: "Selecting this room type will allow all attendees to join with their mics and cameras enabled.",
          }}
          onCancel={() => setConfirmRoom("")}
          onAccept={onAcceptRoomType}
        />
      )}
    </>
  );
};

export default EventOptions;
