import React from "react";
import cotechLogo from "../../assets/images/landings/cotech-logo.png";
import fireweedMetalLogo from "../../assets/images/landings/fireweed-metal-logo.png";
import karoraLogo from "../../assets/images/landings/karora-logo.png";
import reunionGoldLogo from "../../assets/images/landings/reunion-gold-logo.png";

const LandingTrusted = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-landing-trusted">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-section-header">
          <div className="events-page-landing-section-header-tag text-center">
            POWERING THE WORLD’S BEST IR professionals
          </div>
          <h2 className="events-page-landing-section-header-heading text-center">
            Trusted by more than 400 Public Companies
          </h2>
        </div>
      </div>
      <div className="events-page-landing-trusted-content">
        <img src={reunionGoldLogo} alt="reunion-gold " />
        <img src={fireweedMetalLogo} alt="fireweed-metals" />
        <img src={cotechLogo} alt="cotech" />
        <img src={karoraLogo} alt="karora-" />
      </div>
    </section>
  );
};

export default LandingTrusted;
