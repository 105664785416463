import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SEO from "../Common/SEO";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import ComoanyTabs from "./ComoanyTabs";
import CompanyBanner from "./CompanyBanner";
import { SESSION_AUTH } from "../../common/auth";
import { COMPANY_SERVICES } from "../../services";
import "./style.scss";

const Company = () => {
  const { orgSlug } = useParams();
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetchedRef = useRef(false);
  const [companyData, setCompanyData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (dataFetchedRef.current || !orgSlug) return;
    dataFetchedRef.current = true;

    COMPANY_SERVICES.profile({
      slug: orgSlug,
      userId: isUserSession.userId,
      org: isUserSession?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setCompanyData(data.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orgSlug, isUserSession, LOGOUT_USER_SESSION]);

  return companyData ? (
    <React.Fragment>
      <SEO
        title={`${companyData?.orgName} | hosted events and featured speakers on 6ix Events`}
      />
      <div className="events6ix-layout-page-middle">
        <CompanyBanner {...companyData} />
        <ComoanyTabs {...companyData} />
      </div>
    </React.Fragment>
  ) : loading ? (
    <FullPageProgress fixed />
  ) : null;
};

export default Company;
