import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMedia } from "react-use";
import { config as cssConfig } from "@100mslive/roomkit-react";
import AccountBillingSettings from "../components/Account/Settings/AccountBillingSettings";
import AccountGeneralSettings from "../components/Account/Settings/AccountGeneralSettings";
import AccountMemberSettings from "../components/Account/Settings/AccountMemberSettings";
import AccountSettingsSidebar from "../components/Account/Settings/AccountSettingsSidebar";
import AccountSettingsTopbar from "../components/Account/Settings/AccountSettingsTopbar";
import SEO from "../components/Common/SEO";
import PageHeader from "../components/PageHeader";
import { useGlobalValue } from "../GlobalContext";
import { SESSION_AUTH } from "../common/auth";
import "../components/Account/Settings/style.scss";

const AccountSettingPage = () => {
  const { settingType } = useParams();
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const { setLoginUserVerify } = useGlobalValue();
  const isMobile = useMedia(cssConfig.media.md);

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    } else if (!isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    } else if (
      isUserSession?.org?.isAccountOwner &&
      !isUserSession?.org?.onBoarding
    ) {
      return navigate("/on-boarding?continueUrl=/account/setting");
    }
    return () => null;
  }, [isUserSession, navigate, setLoginUserVerify]);

  return (
    <React.Fragment>
      <SEO title="Settings | 6ix Events" />
      <div className="events-page-account-setting events6ix-layout-page">
        <PageHeader type="overview" />
        <div className="events6ix-layout-page-content">
          <div className="events6ix-layout-page-middle">
            {!isMobile ? <AccountSettingsTopbar /> : null}
            <div className="events-page-account-setting-inner">
              <AccountSettingsSidebar />
              {settingType === "members" ? (
                <AccountMemberSettings />
              ) : settingType === "billing" ? (
                <AccountBillingSettings />
              ) : (
                <AccountGeneralSettings />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccountSettingPage;
