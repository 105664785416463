import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Box, Flex } from "@100mslive/roomkit-react";
import { UserIcon } from "../../icons";
import SEO from "../Common/SEO";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { COMPANY_SERVICES } from "../../services";

const CompanySpeakers = props => {
  const { orgId, orgName } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetchedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const limit = 20;
  const { ref: loadMoreItemsBox, inView: loadMoreItemsBoxVisible } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (dataFetchedRef.current || !orgId) return;
    dataFetchedRef.current = true;
    setLoading(true);

    COMPANY_SERVICES.speakers({
      id: orgId,
      limit: 20,
      page: page,
      userId: isUserSession.userId,
      org: isUserSession?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          updateData(data.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, orgId, isUserSession, LOGOUT_USER_SESSION]);

  useEffect(() => {
    if (!loadMoreItemsBoxVisible) return;
    loadMoreContent();
  }, [loadMoreItemsBoxVisible]);

  const updateData = data => {
    if (data && data?.length > 0) {
      setSpeakers(old => [...old, ...data]);
      setHasMore(data.length === limit);
    } else {
      setHasMore(false);
    }
  };
  const loadMoreContent = () => {
    dataFetchedRef.current = false;
    setPage(page => page + 1);
  };

  return (
    <>
      <SEO
        title={`${orgName || "Profile"} | Featured speakers on 6ix Events`}
      />
      {speakers && (
        <Flex className="featured-presenters  w-100" direction="column">
          <Box className="featured-presenters-heading">Featured Presenters</Box>
          {speakers && speakers?.length ? (
            speakers?.map((listItem, key) => {
              return (
                <Box className="featured-presenters-single w-100" key={key}>
                  <Flex>
                    <Box className="featured-presenters-img">
                      {listItem?.profileImage ? (
                        <img
                          src={listItem?.profileImage}
                          alt="speaker profile"
                        />
                      ) : (
                        <UserIcon />
                      )}
                    </Box>

                    <Box className="w-100">
                      <Flex>
                        <Box>
                          <div className="featured-presenters-name">
                            {listItem?.name}
                          </div>
                          <div className="featured-presenters-profile">
                            {listItem?.jobTitle}{" "}
                            {listItem?.work && <> at {listItem?.work}</>}
                          </div>
                        </Box>

                        {listItem.companyLogo && (
                          <Box
                            className="featured-presenters-logo"
                            style={{
                              backgroundColor: listItem?.companyLogoColor,
                            }}
                          >
                            <img src={listItem?.companyLogo} alt="logo" />
                          </Box>
                        )}
                      </Flex>

                      {listItem?.bio && (
                        <div className="featured-presenters-text">
                          {listItem?.bio}
                        </div>
                      )}
                    </Box>
                  </Flex>
                </Box>
              );
            })
          ) : !loading ? (
            <div className="event-page-explore-content-empty">
              Speakers not available, try again later
            </div>
          ) : (
            <></>
          )}
        </Flex>
      )}
      {loading ? (
        <Box>
          <FullPageProgress />
        </Box>
      ) : hasMore ? (
        <div className="eventsLoadMoreProgrss" ref={loadMoreItemsBox}></div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanySpeakers;
