import { Fragment } from "react";
import { CrossIcon } from "@100mslive/react-icons";
import { Flex, Text } from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../common/constants";

export const WidgetScreen = props => {
  const { hasWidget } = props;
  const toggleWidget = useSidepaneToggle(SIDE_PANE_OPTIONS.WIDGET);

  return (
    <Fragment>
      <Flex direction="column" css={{ size: "100%" }}>
        <Flex align="center" css={{ w: "100%", mb: "$10" }}>
          <Text css={{ fontWeight: "$semiBold", mr: "$4" }}>Widget</Text>

          <IconButton
            onClick={toggleWidget}
            css={{ w: "$11", h: "$11", ml: "auto" }}
          >
            <CrossIcon />
          </IconButton>
        </Flex>
        <Flex
          css={{
            width: "100%",
            height: "100%",
            textAlign: "center",
          }}
          align="center"
          justify="center"
        >
          {hasWidget ? (
            <iframe
              title="Event Widget"
              width="100%"
              height="100%"
              src={hasWidget}
            ></iframe>
          ) : (
            <Text>There are no widget here</Text>
          )}
        </Flex>
      </Flex>
    </Fragment>
  );
};
