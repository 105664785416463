import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { Flex, Input, Label } from "@100mslive/roomkit-react";
import {
  auth,
  signInWithApple,
  signInWithEmailPassword,
  signInWithGoogle,
} from "../../../firebase";
import { useGlobalValue } from "../../../GlobalContext";
import { AppleIcon, GoogleIcon, PencilIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";
import { isValidEmail } from "../../../common/utils";
import { USER_SERVICES } from "../../../services";

const LoginWithPassword = props => {
  const { SET_USER_SESSION } = SESSION_AUTH();
  const { handleLogin, setPasswordLogin, email, setEmail } = props;
  const { setLoggedinNotified } = useGlobalValue();
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1);

  const loginEmail = async () => {
    if (!email || (email && !isValidEmail(email))) {
      ToastManager.addToast({ title: "Enter your email address" });
    } else {
      setLoader(true);
      USER_SERVICES.loginEmail({ email: email })
        .then(data => {
          if (data?.code === 201) {
            // setStep(2);
            setPasswordLogin(1);
          } else if (data?.code === 200) {
            SET_USER_SESSION(data?.data);
            if (handleLogin) handleLogin();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => setLoader(false));
    }
  };
  const login = async () => {
    setLoader(true);
    if (!email || (email && !isValidEmail(email))) {
      ToastManager.addToast({ title: "Enter your email address" });
    } else if (!password) {
      ToastManager.addToast({ title: "Enter your password" });
    } else {
      const data = await signInWithEmailPassword(email, password);

      if (data?.code === 200 && data?.data) {
        SET_USER_SESSION(data?.data);
        if (handleLogin) handleLogin();
      }
    }
    setLoader(false);
  };

  const loginWithGoogle = async () => {
    setLoader(true);
    const data = await signInWithGoogle();
    if (data?.code === 200 && data?.data) {
      SET_USER_SESSION(data?.data);
      if (handleLogin) handleLogin();
      setLoggedinNotified(new Date());
    }

    setLoader(false);
  };
  const loginWithApple = async () => {
    setLoader(true);
    const data = await signInWithApple();
    if (data?.code === 200 && data?.data) {
      SET_USER_SESSION(data?.data);
      if (handleLogin) handleLogin();
      setLoggedinNotified(new Date());
    }
    setLoader(false);
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageProgress fixed={true} />}
      {step === 1 ? (
        <div className="userLogin-email custom-form">
          <FormControl className="custom-form-group">
            <Label className="custom-form-label" htmlFor="login-email">
              Email
            </Label>
            <Input
              id="login-email"
              placeholder="your@email.com"
              className="custom-form-input"
              type="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              name="email"
            />
          </FormControl>
          <button
            type="button"
            className="userLogin-email-btn mt-20"
            onClick={loginEmail}
            disabled={loader || loading}
          >
            Continue
          </button>

          <div className="userLogin-or">
            <span className="userLogin-or-text">
              <span className="userLogin-or-text-inner">OR LOGIN WITH</span>
            </span>
          </div>
          <Flex className="userLogin-social mt-20" style={{ gap: "20px" }}>
            <button
              onClick={loginWithGoogle}
              type="button"
              className="userLogin-social-btn"
            >
              <GoogleIcon /> Google
            </button>
            <button
              onClick={loginWithApple}
              type="button"
              className="userLogin-social-btn mt-0"
            >
              <AppleIcon /> Apple
            </button>
          </Flex>
        </div>
      ) : step === 2 ? (
        <div className="userLogin-email custom-form">
          <FormControl className="custom-form-group">
            <Label className="custom-form-label" htmlFor="login-email2">
              Email
            </Label>
            <div
              id="login-email2"
              className="custom-form-input"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setStep(1);
              }}
            >
              {email}
            </div>
            <div
              className="custom-form-icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setStep(1);
              }}
            >
              <PencilIcon />
            </div>
          </FormControl>
          <FormControl className="custom-form-group">
            <Label className="custom-form-label" htmlFor="login-password">
              Password
            </Label>
            <Input
              id="login-password"
              placeholder="* * * * * *"
              className="custom-form-input"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              type="password"
              name="password"
            />
          </FormControl>
          <Flex className="mt-0 mb-20">
            <div
              className="userLogin-email-link text-left"
              onClick={() => {
                setPasswordLogin(3);
              }}
            >
              Forgot password?
            </div>
          </Flex>

          <button
            type="button"
            className="userLogin-email-btn"
            onClick={login}
            disabled={loader || loading}
          >
            Continue with password
          </button>

          <div className="userLogin-or">
            <span className="userLogin-or-text">or</span>
          </div>

          <div className="userLogin-social">
            <button
              type="button"
              className="userLogin-social-btn"
              onClick={() => setPasswordLogin(1)}
            >
              Verify with Email Link
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default LoginWithPassword;
