import { WebhookIcon } from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import {
  useIsSidepaneTypeOpen,
  useSidepaneToggle,
} from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../common/constants";

export const WidgetToggle = props => {
  const { hasWidget } = props;
  const isWidgetOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.WIDGET);
  const toggleWidget = useSidepaneToggle(SIDE_PANE_OPTIONS.WIDGET);

  return hasWidget ? (
    <Tooltip key="widget" title={`${isWidgetOpen ? "Close" : "Open"} widget`}>
      <IconButton
        onClick={toggleWidget}
        active={!isWidgetOpen}
        data-testid="widget_btn"
      >
        <WebhookIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );
};
