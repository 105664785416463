import React from "react";
import { Outlet } from "react-router-dom";
import EventFooter from "../components/Event/EventFooter";
import EventHeader from "../components/Event/EventHeader";
// import EventHeaderSidebar from "../components/Event/EventHeader/EventHeaderSidebar";
// import { useGlobalValue } from "../GlobalContext";

const EventsLayout = () => {
  // const { openSidebar } = useGlobalValue();
  return (
    <React.Fragment>
      <EventHeader />
      <div className="events6ix-layout-wrapper">
        {/* {openSidebar && <EventHeaderSidebar />} */}
        <div className="events6ix-layout-wrapper-content">
          <Outlet />
          <EventFooter />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventsLayout;
