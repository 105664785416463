import React, { useState } from "react";
import { Button, FormControl, TextareaAutosize } from "@mui/material";
import { Box, Input } from "@100mslive/roomkit-react";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { isValidEmail } from "../../common/utils";
import { OTHER_SERVICES } from "../../services";

const LandingTouch = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const saveForm = async () => {
    if (!name) {
      ToastManager.addToast({ title: "Please fill your name" });
      return false;
    } else if (!email || !isValidEmail(email)) {
      ToastManager.addToast({ title: "Please fill email" });
      return false;
    } else if (!message) {
      ToastManager.addToast({ title: "Please fill message" });
      return false;
    }

    setLoading(true);

    try {
      const data = await OTHER_SERVICES.contact({
        name: name,
        email: email,
        message: message,
      });
      setLoading(false);
      if (data.code === 200) {
        setName("");
        setEmail("");
        setMessage("");
        ToastManager.addToast({ title: "Your message sent" });
      } else {
        ToastManager.addToast({ title: data?.message });
      }
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <Box
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            left: "0px",
            top: "0px",
            zIndex: 999,
          }}
        >
          <FullPageProgress />
        </Box>
      ) : null}
      <section className="events-page-landing-section events-page-landing-section-space-top events-page-landing-touch">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-touch-content">
            <div className="events-page-landing-touch-content-box">
              <h2 className="events-page-landing-touch-content-box-title">
                Get in Touch
              </h2>
              <div className="events-page-landing-touch-content-box-text">
                "I have really enjoyed participation in the 6ix summits –
                especially the panels. It’s a very directed message to
                investors, well handled and executed with good questions and
                good Q&A period. The formats are such that companies have the
                ability to talk about their companies in the context of
                different topics. It’s a great format for getting your message
                out."
              </div>
              <div className="events-page-landing-touch-content-box-contact">
                <div className="events-page-landing-touch-content-box-contact-title">
                  Rick Van Nieuwenhuyse
                </div>
                <div className="events-page-landing-touch-content-box-contact-text">
                  President and CEO <br />
                  Contango ORE
                </div>
              </div>
            </div>

            <div className="events-page-landing-touch-content-box">
              <FormControl className="custom-form-group">
                <label htmlFor="name" className="custom-form-label big-label">
                  Full Name
                </label>
                <Input
                  id="name"
                  name="name"
                  className="custom-form-input"
                  value={name}
                  onChange={e =>
                    setName(e.target?.value?.replace(/(<([^>]+)>)/gi, ""))
                  }
                />
              </FormControl>
              <FormControl className="custom-form-group">
                <label htmlFor="email" className="custom-form-label big-label">
                  Email
                </label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  className="custom-form-input"
                  value={email}
                  onChange={e =>
                    setEmail(e.target?.value?.replace(/(<([^>]+)>)/gi, ""))
                  }
                />
              </FormControl>
              <FormControl className="custom-form-group">
                <label
                  htmlFor="message"
                  className="custom-form-label big-label"
                >
                  Message
                </label>
                <TextareaAutosize
                  id="user-bio"
                  name="bio"
                  value={message}
                  onChange={e =>
                    setMessage(e.target?.value?.replace(/(<([^>]+)>)/gi, ""))
                  }
                  className="custom-form-input"
                  minRows={4}
                  maxRows={4}
                />
              </FormControl>

              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                onClick={saveForm}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingTouch;
