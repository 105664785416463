import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "@100mslive/roomkit-react";

const EventFooterContentInvest = () => {
  return (
    <Flex className="events6ix-layout-footer-content-left-row">
      <div className="events6ix-layout-footer-content-left-row-content">
        <div className="events6ix-layout-footer-content-left-row-content-heading">
          Invest Capital
        </div>
        <div className="events6ix-layout-footer-content-left-row-content-text">
          <div className="events6ix-layout-footer-content-space">
            Tools to help you invest better.
          </div>
          <div className="events6ix-layout-footer-content-space">
            <ul>
              <li>Discover and access diverse global investment events</li>
              <li>
                Engage directly with company management through video-first
                events
              </li>
              <li>Access detailed, trustworthy information about events</li>
              <li>Learn from other investors and analysts</li>
            </ul>
          </div>
          <div className="events6ix-layout-footer-content-space">
            Trusted by 100,000+ retail, accredited & institutional investors.
          </div>
        </div>
      </div>
      <Flex className="events6ix-layout-footer-content-left-row-menu">
        <Flex className="events6ix-layout-footer-content-left-row-menu-box">
          <div className="events6ix-layout-footer-content-left-row-menu-box-heading">
            DISCOVER
          </div>

          <Link
            to="/explore"
            className="events6ix-layout-footer-content-left-row-menu-box-link"
          >
            Explore the Marketplace
          </Link>

          <Link
            to="https://ai.6ix.com"
            className="events6ix-layout-footer-content-left-row-menu-box-link"
          >
            Chat with 6ix AI
          </Link>
        </Flex>

        <Flex className="events6ix-layout-footer-content-left-row-menu-box">
          <div className="events6ix-layout-footer-content-left-row-menu-box-heading">
            ENGAGE
          </div>
        </Flex>

        <Flex className="events6ix-layout-footer-content-left-row-menu-box">
          <div className="events6ix-layout-footer-content-left-row-menu-box-heading">
            TRANSACT
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EventFooterContentInvest;
