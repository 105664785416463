import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Moment from "moment";
import { Box, Flex } from "@100mslive/roomkit-react";
import dummyContent from "../../assets/images/report/investor-poster.png";
import { InvestorChart } from "../Chart/InvestorChart";
import { SentimentChart } from "../Chart/SentimentChart";
import { ShareHolderChart } from "../Chart/ShareHolderChart";
import { StatusChart } from "../Chart/StatusChart";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { AnalyticsCSV } from "./AnalyticsCSV";
import { AnalyticsTable } from "./AnalyticsTable";
import { SESSION_AUTH } from "../../common/auth";
import { ANALYTICS_SERVICES } from "../../services";
import "./Analytics.scss";

const Analytics = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [dataFetched, setDataFetched] = useState(false);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [totalRegistration, setTotalRegistration] = useState(0);
  const [totalAttendees, setTotalAttendees] = useState(0);
  const [totalChequeSize, settTotalChequeSize] = useState(0);
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [totalRequestedMeeting, settotalRequestedMeeting] = useState(0);
  const [totalInvited, setTotalInvited] = useState(0);
  const [investorChartData, setInvestorChartData] = useState("");
  const [shareHolderChartData, setShareHolderChartData] = useState("");
  const [sentimentChartData, setSentimentChartData] = useState("");
  const [statusChartData, setStatusChartData] = useState("");
  const [isSubscription, setIsSubscription] = useState("");
  const [loading, setLoading] = useState(true);

  const [pageViews, setPageViews] = useState();
  const [registrants, setRegistrants] = useState(0);
  const [attendees, setAttendees] = useState(0);
  const [getreplays, setGetreplays] = useState(0);
  const [ondemands, setOndemands] = useState(0);

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);

    setLoading(true);
    try {
      ANALYTICS_SERVICES.analytics({
        userId: userData.userId,
        org: userData?.org?.id,
        id: eventId,
      })
        .then(data => {
          if (data.code === 200) {
            setEventName(data?.data?.eventName);
            setEventDate(data?.data?.eventDate);
            setIsSubscription(data.data?.isSubscription);
            setTotalRegistration(data?.data?.totalRegister || 0);
            settTotalChequeSize(data.data?.totalChequeSize || 0);
            setTotalAttendees(data.data?.totalAttends || 0);
            setTotalInvestedAmount(data.data?.totalInvestedAmount || 0);
            settotalRequestedMeeting(data.data?.totalMeetingReq || 0);
            setTotalInvited(data.data?.totalInvited || 0);
            setPageViews(data?.data?.views);
            setRegistrants(data?.data?.registrants || 0);
            setAttendees(data?.data?.attendees || 0);
            setGetreplays(data?.data?.getreplays || 0);
            setOndemands(data?.data?.ondemands || 0);

            if (data.data?.graph) {
              setInvestorChartData(data.data?.graph?.investor);
              setShareHolderChartData(data.data?.graph?.shareHolder);
              setSentimentChartData(data.data?.graph?.sentiment);
              setStatusChartData(data.data?.graph?.status);
            }
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            navigate("/dashboard");
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, eventId, navigate]);

  const goInvitedUser = () => {
    if (isSubscription) {
      navigate("/event/analytics/invited/" + eventId);
    } else {
      navigate("/account/setting/billing");
    }
  };
  return loading ? (
    <FullPageProgress fixed={true} />
  ) : (
    <React.Fragment>
      <Flex className="events6ix-layout-page-top">
        <h1 className="events6ix-layout-page-top-heading events6ix-layout-page-top-heading-line">
          {eventName}{" "}
          {eventDate
            ? " - " + Moment(eventDate).format("MMM DD, YYYY hh:mm A")
            : null}
        </h1>
        {totalRegistration ? (
          <AnalyticsCSV isSubscription={isSubscription} eventName={eventName} />
        ) : null}
      </Flex>
      <div className=" relationship-container">
        {totalRegistration && totalRegistration > 0 ? (
          <React.Fragment>
            <Flex className="relationship-container-inner">
              <Box
                className="relationship-container-inner-content"
                css={{ position: "relative", r: "$1" }}
              >
                <Flex
                  direction="column"
                  className="relationship-container-inner-content-box"
                >
                  <label> Total Registrants </label>
                  <span>{totalRegistration}</span>
                </Flex>
              </Box>
              <Box
                className="relationship-container-inner-content"
                css={{ position: "relative", r: "$1" }}
              >
                <Flex
                  direction="column"
                  className="relationship-container-inner-content-box"
                >
                  <label> Total Attendees </label>
                  <span>{totalAttendees}</span>
                </Flex>
              </Box>
              <Box
                className="relationship-container-inner-content"
                css={{ position: "relative", r: "$1" }}
              >
                <Flex
                  direction="column"
                  className="relationship-container-inner-content-box"
                >
                  <label> Cheque Size (Total) </label>

                  <NumericFormat
                    value={totalChequeSize}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                    renderText={(value, props) => (
                      <span {...props}>{value}</span>
                    )}
                  />
                </Flex>
              </Box>
              <Box
                className="relationship-container-inner-content"
                css={{ position: "relative", r: "$1" }}
              >
                <Flex
                  direction="column"
                  className="relationship-container-inner-content-box"
                >
                  <label> Invested Amount (Total) </label>

                  <NumericFormat
                    value={totalInvestedAmount}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                    renderText={(value, props) => (
                      <span {...props}>{value}</span>
                    )}
                  />
                </Flex>
              </Box>

              <Box
                className="relationship-container-inner-content"
                css={{ position: "relative", r: "$1" }}
              >
                <Flex
                  direction="column"
                  className="relationship-container-inner-content-box"
                >
                  <label> Meeting Requests (Total) </label>
                  <span>{totalRequestedMeeting}</span>
                </Flex>
              </Box>

              <Box
                className="relationship-container-inner-content"
                css={{ position: "relative", r: "$1", cursor: "pointer" }}
                onClick={goInvitedUser}
              >
                <Flex
                  direction="column"
                  className="relationship-container-inner-content-box"
                >
                  <label> Invited (Total) </label>
                  <span>{totalInvited}</span>
                </Flex>
              </Box>

              {registrants ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Registers </label>
                    <span>{registrants}</span>
                  </Flex>
                </Box>
              ) : null}
              {pageViews?.register ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Register Page Views </label>
                    <span>{pageViews?.register}</span>
                  </Flex>
                </Box>
              ) : null}

              {attendees ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Attendees </label>
                    <span>{attendees}</span>
                  </Flex>
                </Box>
              ) : null}
              {pageViews?.attendee ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Attendee Page Views </label>
                    <span>{pageViews?.attendee}</span>
                  </Flex>
                </Box>
              ) : null}
              {getreplays ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Get the Replay </label>
                    <span>{getreplays}</span>
                  </Flex>
                </Box>
              ) : null}
              {pageViews?.replay ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Replay Page Views </label>
                    <span>{pageViews?.replay}</span>
                  </Flex>
                </Box>
              ) : null}
              {ondemands ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> On-Demands </label>
                    <span>{ondemands}</span>
                  </Flex>
                </Box>
              ) : null}
              {pageViews?.ondemand ? (
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> On-Demand Page Views </label>
                    <span>{pageViews?.ondemand}</span>
                  </Flex>
                </Box>
              ) : null}
            </Flex>
            <Flex className="relationship-container-inner-charts">
              {investorChartData ? (
                <Box className="relationship-container-inner-charts-col">
                  <InvestorChart chartData={investorChartData} />
                </Box>
              ) : null}

              {shareHolderChartData ? (
                <Box className="relationship-container-inner-charts-col">
                  <ShareHolderChart chartData={shareHolderChartData} />
                </Box>
              ) : null}
              {sentimentChartData ? (
                <Box className="relationship-container-inner-charts-col">
                  <SentimentChart chartData={sentimentChartData} />
                </Box>
              ) : null}
            </Flex>
            <Flex className="relationship-container-inner-charts">
              {statusChartData ? (
                <Box className="relationship-container-inner-charts-col relationship-container-inner-charts-col-full">
                  <StatusChart chartData={statusChartData} />
                </Box>
              ) : null}
            </Flex>
            <AnalyticsTable
              totalRows={totalRegistration}
              isSubscription={isSubscription}
            />
          </React.Fragment>
        ) : (
          <>
            <img src={dummyContent} alt="report data" />
          </>
        )}

        {!isSubscription ? (
          <div className="relationship-upgrade">
            <div className="relationship-upgrade-title">
              Upgrade your account to see the <br /> investors who want to meet
              you
            </div>
            <Button
              component={Link}
              to="/account/setting/billing"
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary"
            >
              UPGRADE TO GET ACCESS
            </Button>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Analytics;
