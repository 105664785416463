import React from "react";
import SEO from "../components/Common/SEO";
import LandingTouch from "../components/Landing/LandingTouch";

const SupportPage = () => {
  return (
    <React.Fragment>
      <SEO title="Support | 6ix Events" />
      <div className="events-page-support events6ix-layout-page">
        <div className="events6ix-layout-page-content no-space">
          <section className="events-page-landing-section events-page-landing-section-space">
            <LandingTouch />
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupportPage;
