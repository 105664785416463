import { ROLES } from "./roles";
import {
  CREATE_MEETING_TOKEN,
  QUERY_PARAM_SKIP_PREVIEW,
  QUESTION_TYPE,
} from "./constants";
export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

/**
 * TODO: this is currently an O(N**2) function, don't use with peer lists, it's currently
 * being used to find intersection between list of role names where the complexity shouldn't matter much.
 */
export const arrayIntersection = (a, b) => {
  if (a === undefined || b === undefined) {
    return [];
  }
  // ensure "a" is the bigger array
  if (b.length > a.length) {
    let t = b;
    b = a;
    a = t;
  }
  return a.filter(function (e) {
    return b.indexOf(e) > -1;
  });
};

export const getMetadata = metadataString => {
  try {
    return metadataString === "" ? {} : JSON.parse(metadataString);
  } catch (error) {
    return {};
  }
};

export const metadataProps = function (peer) {
  return {
    isHandRaised: getMetadata(peer.metadata)?.isHandRaised,
  };
};

export const isScreenshareSupported = () => {
  return typeof navigator.mediaDevices.getDisplayMedia !== "undefined";
};

export const getDefaultMeetingUrl = () => {
  return (
    window.location.href.replace("meeting", "preview") +
    `?${QUERY_PARAM_SKIP_PREVIEW}=true`
  );
};

export const getMeetingRecordingUrl = roomId => {
  //return `https://${process.env.REACT_APP_MEETING_DOMAIN}/preview/${roomId}/webrtc-viewer/?${QUERY_PARAM_SKIP_PREVIEW}=true&${QUERY_PARAM_NAME}=${process.env.REACT_APP_MEETING_NAME}`;
  let t = CREATE_MEETING_TOKEN({
    roomId: roomId,
    role: ROLES.RECORDING,
    profile: { name: process.env.REACT_APP_MEETING_NAME },
  });
  // console.log(
  //   `${process.env.REACT_APP_PRODUCTION_URL}/preview/${roomId}/?meetingToken=${t}&${QUERY_PARAM_SKIP_PREVIEW}=true`,
  //   "Recordingurl"
  // );
  return `${process.env.REACT_APP_PRODUCTION_URL}/preview/${roomId}/?meetingToken=${t}&${QUERY_PARAM_SKIP_PREVIEW}=true`;
};

export const getRoutePrefix = () => {
  return window.location.pathname.startsWith("/streaming") ? "/streaming" : "";
};

export const isStreamingKit = () => {
  return window.location.pathname.startsWith("/streaming");
};

export const isInternalRole = role => role && role.startsWith("__internal");
export const isRecordingRole = role => role === ROLES?.RECORDING;

const compareArrays = (a, b) => {
  if (a.length !== b.length) return false;
  else {
    // Comparing each element of your array
    for (var i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }
};

export const checkCorrectAnswer = (answer, localPeerResponse, type) => {
  if (type === QUESTION_TYPE.SINGLE_CHOICE) {
    return answer?.option === localPeerResponse?.option;
  } else if (type === QUESTION_TYPE.MULTIPLE_CHOICE) {
    return (
      answer?.options &&
      localPeerResponse?.options &&
      compareArrays(answer?.options, localPeerResponse?.options)
    );
  }
};

export const isValidTextInput = (text, minLength = 2, maxLength = 100) => {
  return text && text.length >= minLength && text.length <= maxLength;
};

export const isValidEmail = email => {
  let emailValidRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(emailValidRegex)) {
    let atpos = email.indexOf("@");
    let dotpos = email.lastIndexOf(".");
    if (atpos < 1 || dotpos - atpos < 2) {
      return false;
    }
    return true;
  }
  return false;
};

export const sizeFormatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const CSVToJSONConvert = (data, delimiter = ",") => {
  var lines = data.split("\n");
  var result = [];
  var headers = lines[0].split(",");
  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");
    for (var j = 0; j < headers.length; j++) {
      obj[headers[j].trim()] = currentline[j]
        ? currentline[j].replace(/(?:\\[rn]|[\r\n]+)+/g, "")
        : "";
    }
    result.push(obj);
  }

  return result;
};

export const ConvertNameFistLast = name => {
  let firstName = name;
  let lastName = "";
  if (firstName.indexOf(" ") > -1) {
    let names = firstName.split(" ");
    firstName = names[0];
    lastName = names?.length > 1 ? names[names.length - 1] : "";
  } else if (firstName.indexOf(".") > -1) {
    let names = firstName.split(".");
    firstName = names[0];
    lastName = names?.length > 1 ? names[names.length - 1] : "";
  }
  if (firstName) firstName = firstName[0].toUpperCase() + firstName.slice(1);
  if (lastName) lastName = lastName[0].toUpperCase() + lastName.slice(1);

  return { firstName, lastName };
};

export const reminderScheduleList = () => {
  return [
    { value: "now", label: "Now" },
    { value: "hours24", label: "24 hours before the event" },
    { value: "hour1", label: "1 hour before the event" },
    { value: "live", label: "Once event is live" },
  ];
};

export const accountTeamRoleList = {
  member: {
    value: "member",
    name: "Member",
    info: "View and manage events",
  },
  admin: {
    value: "admin",
    name: "Admin",
    info: "Manage team members and events",
  },
  owner: {
    value: "owner",
    name: "Owner",
    info: "Manage team members, events and billing",
  },
};
export const INVESTOR_TYPE_LIST = {
  Retail: {
    value: "Retail",
    name: "Retail ($25K)",
    size: 25000,
  },
  Accredited: {
    value: "Accredited",
    name: "Accredited ($250K)",
    size: 250000,
  },
  Institutional: {
    value: "Institutional",
    name: "Institutional ($2500K)",
    size: 2500000,
  },
  Corporate: {
    value: "Corporate",
    name: "Corporate ($1000K)",
    size: 1000000,
  },
};

export const GOOGLE_LANGUAGE_LIST = [
  { name: "Afrikaans", lang: "af" },
  { name: "Albanian", lang: "sq" },
  { name: "Amharic", lang: "am" },
  { name: "Arabic", lang: "ar" },
  { name: "Armenian", lang: "hy" },
  { name: "Assamese", lang: "as" },
  { name: "Aymara", lang: "ay" },
  { name: "Azerbaijani", lang: "az" },
  { name: "Bambara", lang: "bm" },
  { name: "Basque", lang: "eu" },
  { name: "Belarusian", lang: "be" },
  { name: "Bengali", lang: "bn" },
  { name: "Bhojpuri", lang: "bho" },
  { name: "Bosnian", lang: "bs" },
  { name: "Bulgarian", lang: "bg" },
  { name: "Catalan", lang: "ca" },
  { name: "Cebuano", lang: "ceb" },
  //{ name: "Chinese (Simplified)", lang: "zh-CN" },
  { name: "Chinese", lang: "zh-TW", popular: true },
  { name: "Corsican", lang: "co" },
  { name: "Croatian", lang: "hr" },
  { name: "Czech", lang: "cs" },
  { name: "Danish", lang: "da" },
  { name: "Dhivehi", lang: "dv" },
  { name: "Dogri", lang: "doi" },
  { name: "Dutch", lang: "nl" },
  { name: "English", lang: "en", popular: true },
  { name: "Esperanto", lang: "eo" },
  { name: "Estonian", lang: "et" },
  { name: "Ewe", lang: "ee" },
  { name: "Filipino (Tagalog)	", lang: "fil" },
  { name: "Finnish", lang: "fi" },
  { name: "French", lang: "fr", popular: true },
  { name: "Frisian", lang: "fy" },
  { name: "Galician", lang: "gl" },
  { name: "Georgian", lang: "ka" },
  { name: "German", lang: "de", popular: true },
  { name: "Greek", lang: "el" },
  { name: "Guarani", lang: "gn" },
  { name: "Gujarati", lang: "gu" },
  { name: "Haitian Creole", lang: "ht" },
  { name: "Hausa", lang: "ha" },
  { name: "Hawaiian", lang: "haw" },
  { name: "Hebrew", lang: "iw" },
  { name: "Hindi", lang: "hi", popular: true },
  { name: "Hmong", lang: "hmn" },
  { name: "Hungarian", lang: "hu" },
  { name: "Icelandic", lang: "is" },
  { name: "Igbo", lang: "ig" },
  { name: "Ilocano", lang: "ilo" },
  { name: "Indonesian", lang: "id" },
  { name: "Irish", lang: "ga" },
  { name: "Italian", lang: "it" },
  { name: "Japanese", lang: "ja" },
  { name: "Javanese", lang: "jv" },
  { name: "Kannada", lang: "kn" },
  { name: "Kazakh", lang: "kk" },
  { name: "Khmer", lang: "km" },
  { name: "Kinyarwanda", lang: "rw" },
  { name: "Konkani", lang: "gom" },
  { name: "Korean", lang: "ko" },
  { name: "Krio", lang: "kri" },
  { name: "Kurdish", lang: "ku" },
  { name: "Kurdish (Sorani)	", lang: "ckb" },
  { name: "Kyrgyz", lang: "ky" },
  { name: "Lao", lang: "lo" },
  { name: "Latin", lang: "la" },
  { name: "Latvian", lang: "lv" },
  { name: "Lingala", lang: "ln" },
  { name: "Lithuanian", lang: "lt" },
  { name: "Luganda", lang: "lg" },
  { name: "Luxembourgish", lang: "lb" },
  { name: "Macedonian", lang: "mk" },
  { name: "Maithili", lang: "mai" },
  { name: "Malagasy", lang: "mg" },
  { name: "Malay", lang: "ms" },
  { name: "Malayalam", lang: "ml" },
  { name: "Maltese", lang: "mt" },
  { name: "Maori", lang: "mi" },
  { name: "Marathi", lang: "mr" },
  { name: "Meiteilon (Manipuri)	", lang: "mni-Mtei" },
  { name: "Mizo	", lang: "lus" },
  { name: "Mongolian", lang: "mn" },
  { name: "Myanmar (Burmese)", lang: "my" },
  { name: "Nepali", lang: "ne" },
  { name: "Norwegian", lang: "no" },
  { name: "Nyanja (Chichewa)	", lang: "ny" },
  { name: "Odia (Oriya)", lang: "or" },
  { name: "Oromo", lang: "om" },
  { name: "Pashto", lang: "ps" },
  { name: "Persian", lang: "fa" },
  { name: "Polish", lang: "pl" },
  { name: "Portuguese (Portugal, Brazil)", lang: "pt" },
  { name: "Punjabi", lang: "pa" },
  { name: "Quechua", lang: "qu" },
  { name: "Romanian", lang: "ro" },
  { name: "Russian", lang: "ru" },
  { name: "Samoan", lang: "sm" },
  { name: "Sanskrit", lang: "sa" },
  { name: "Scots Gaelic", lang: "gd" },
  { name: "Sepedi", lang: "nso" },
  { name: "Serbian", lang: "sr" },
  { name: "Sesotho", lang: "st" },
  { name: "Shona", lang: "sn" },
  { name: "Sindhi", lang: "sd" },
  { name: "Sinhala (Sinhalese)", lang: "si" },
  { name: "Slovak", lang: "sk" },
  { name: "Slovenian", lang: "sl" },
  { name: "Somali", lang: "so" },
  { name: "Spanish", lang: "es", popular: true },
  { name: "Sundanese", lang: "su" },
  { name: "Swahili", lang: "sw" },
  { name: "Swedish", lang: "sv" },
  { name: "Tagalog (Filipino)	", lang: "tl" },
  { name: "Tajik", lang: "tg" },
  { name: "Tamil", lang: "ta" },
  { name: "Tatar", lang: "tt" },
  { name: "Telugu", lang: "te" },
  { name: "Thai", lang: "th" },
  { name: "Tigrinya", lang: "ti" },
  { name: "Tsonga", lang: "ts" },
  { name: "Turkish", lang: "tr" },
  { name: "Turkmen", lang: "tk" },
  { name: "Twi (Akan)	", lang: "ak" },
  { name: "Ukrainian", lang: "uk" },
  { name: "Urdu", lang: "ur" },
  { name: "Uyghur", lang: "ug" },
  { name: "Uzbek", lang: "uz" },
  { name: "Vietnamese", lang: "vi" },
  { name: "Welsh", lang: "cy" },
  { name: "Xhosa", lang: "xh" },
  { name: "Yiddish", lang: "yi" },
  { name: "Yoruba", lang: "yo" },
  { name: "Zulu", lang: "zu" },
];

export const APPS_LIST = [
  {
    icon: "events",
    title: "Events",
    text: "Attend & host investor events",
    link: "/",
    active: true,
  },
];
