import React from "react";
import PreviewScreen from "../components/PreviewScreen";
//const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));

const EventPreviewPage = props => {
  return (
    <React.Fragment>
      <PreviewScreen {...props} />
    </React.Fragment>
  );
};

export default EventPreviewPage;
