import React from "react";
export function AngleIcon(props) {
  return props?.small ? (
    <svg
      className={props?.classes || ""}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.47922 10.5346C8.34799 10.6563 8.17465 10.7212 7.99818 10.7212C7.82213 10.7212 7.64837 10.6566 7.51683 10.5343L3.08913 6.42358C2.95192 6.29612 2.8782 6.12333 2.8782 5.94575C2.8782 5.76762 2.95236 5.59431 3.09042 5.46673C3.35878 5.21895 3.78381 5.21951 4.05143 5.46788L7.99819 9.13208L11.9449 5.46805C12.2125 5.21963 12.6377 5.21897 12.906 5.46679C13.1883 5.72767 13.189 6.16198 12.9074 6.42354L8.47922 10.5346Z"
        fill="#E9E9E9"
      />
    </svg>
  ) : (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0998 13.8684C10.9357 14.0205 10.719 14.1017 10.4985 14.1017C10.2784 14.1017 10.0612 14.021 9.89677 13.868L4.36215 8.72967C4.19063 8.57035 4.09848 8.35436 4.09848 8.13238C4.09848 7.90973 4.19119 7.69308 4.36376 7.5336C4.6992 7.22389 5.2305 7.22458 5.56502 7.53505L10.4985 12.1153L15.4319 7.53526C15.7664 7.22473 16.2978 7.22391 16.6332 7.53369C16.9861 7.85978 16.987 8.40267 16.635 8.72962L11.0998 13.8684Z"
        fill="#EBD18A"
      />
    </svg>
  );
}
export default AngleIcon;
