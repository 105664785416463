import React from "react";
import SEO from "../components/Common/SEO";
import InvestorInvited from "../components/Investor/Invited";
import InvestorReport from "../components/Investor/Report";
import PageHeader from "../components/PageHeader";

export function InvestorReportPage() {
  return (
    <React.Fragment>
      <SEO title="Investors | 6ix Events" />
      <div className="events-page-investor events6ix-layout-page">
        <PageHeader />
        <div className="events6ix-layout-page-content">
          <InvestorReport />
        </div>
      </div>
    </React.Fragment>
  );
}
export function InvestorInvitedPage() {
  return (
    <React.Fragment>
      <SEO title="Investors | 6ix Events" />
      <div className="events-page-investor events6ix-layout-page">
        <PageHeader />
        <div className="events6ix-layout-page-content">
          <InvestorInvited />
        </div>
      </div>
    </React.Fragment>
  );
}
