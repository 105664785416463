import React, { useEffect, useRef, useState } from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import { UserIcon } from "../../icons";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { EVENT_SERVICES } from "../../services";

const EventSpeakers = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { eventId } = props;
  const dataFetchedRef = useRef(false);
  const [speakers, setSpeakers] = useState("");

  useEffect(() => {
    if (!eventId || dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dataFetchedRef.current = true;
    let parma = {};
    parma["userId"] = isUserSession?.userId;
    parma["org"] = isUserSession?.org?.id;
    parma["id"] = eventId;
    try {
      EVENT_SERVICES.speakersList(parma)
        .then(data => {
          if (data.code === 200) {
            setSpeakers(data?.data?.length > 0 ? data?.data : "");
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: "Error Something went wrong" });
        });
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
  }, [isUserSession, dataFetchedRef, eventId, LOGOUT_USER_SESSION]);

  return (
    speakers && (
      <>
        <Flex className="featured-presenters  w-100" direction="column">
          <div className="com-divider w-100"></div>
          <Box className="featured-presenters-heading">Featured Presenters</Box>
          {speakers?.map((listItem, key) => {
            return (
              <Box className="featured-presenters-single w-100" key={key}>
                <Flex>
                  <Box className="featured-presenters-img">
                    {listItem?.profileImage ? (
                      <img src={listItem?.profileImage} alt="speaker profile" />
                    ) : (
                      <UserIcon />
                    )}
                  </Box>

                  <Box className="w-100">
                    <Flex>
                      <Box>
                        <div className="featured-presenters-name">
                          {listItem?.name}
                        </div>
                        <div className="featured-presenters-profile">
                          {listItem?.jobTitle}{" "}
                          {listItem?.work && <> at {listItem?.work}</>}
                        </div>
                      </Box>

                      {listItem.companyLogo && (
                        <Box
                          className="featured-presenters-logo"
                          style={{
                            backgroundColor: listItem?.companyLogoColor,
                          }}
                        >
                          <img src={listItem?.companyLogo} alt="logo" />
                        </Box>
                      )}
                    </Flex>

                    {listItem?.bio && (
                      <div className="featured-presenters-text">
                        {listItem?.bio}
                      </div>
                    )}
                  </Box>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </>
    )
  );
};

export default EventSpeakers;
