import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { usePrevious } from "react-use";
import {
  HMSRoomState,
  selectIsConnectedToRoom,
  selectRoomState,
  selectSessionMetadata,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Box, Flex } from "@100mslive/roomkit-react";
import { ConferenceMainView } from "../layouts/mainView";
import { Footer } from "./Footer";
import FullPageProgress from "./FullPageProgress";
import { Header } from "./Header";
import { RoleChangeRequestModal } from "./RoleChangeRequestModal";
import { useIsHeadless } from "./AppData/useUISettings";
import { useNavigation } from "./hooks/useNavigation";
import { GET_PUBLIC_SESSION } from "../common/constants";

const Conference = () => {
  const navigate = useNavigation();
  const { roomId: urlSlug } = useParams();
  const isHeadless = useIsHeadless();
  const roomState = useHMSStore(selectRoomState);
  const prevState = usePrevious(roomState);
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  const sessionMetaData = useHMSStore(selectSessionMetadata);
  const [starData, setStarData] = useState([]);
  const [likeData, setLikeData] = useState([]);
  const [deleteMsgData, setDeleteMsgData] = useState([]);
  const [hasWidget, setHasWidget] = useState("");
  const [hasChatQA, setHasChatQA] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!urlSlug) {
      navigate(`/`);
      return;
    }
    if (
      !prevState &&
      !(
        roomState === HMSRoomState.Connecting ||
        roomState === HMSRoomState.Reconnecting ||
        isConnectedToRoom
      )
    ) {
      navigate(`/preview/${urlSlug || ""}`);
    }
  }, [isConnectedToRoom, prevState, roomState, navigate, urlSlug]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      let event = GET_PUBLIC_SESSION("EU_M_Event");
      event = event ? JSON.parse(event) : "";
      setHasWidget(event?.widget || "");
      setHasChatQA(event?.eventChatQA);
    }
    return () => null;
  }, [isFirstRender]);

  useEffect(() => {
    // beam doesn't need to store messages, saves on unnecessary store updates in large calls
    if (isHeadless) {
      hmsActions.ignoreMessageTypes(["chat"]);
    }
  }, [isHeadless, hmsActions]);

  useEffect(() => {
    if (sessionMetaData?.actionData) {
      if (sessionMetaData?.actionData?.action === "star") {
        let message = sessionMetaData.actionData;
        let newStarMessage = [...starData];
        const index = newStarMessage.findIndex(
          ({ sender, senderUserId, time }) =>
            sender === message?.sender &&
            senderUserId === message?.senderUserId &&
            time === message?.time
        );
        if (message?.type === "Add" && index === -1) {
          newStarMessage = [
            ...newStarMessage,
            {
              sender: message?.sender,
              senderUserId: message?.senderUserId,
              time: message?.time,
              actionValue: 1,
            },
          ];
          setStarData(newStarMessage);
        } else if (message?.type === "Remove") {
          newStarMessage = [
            ...newStarMessage.slice(0, index),
            ...newStarMessage.slice(index + 1),
          ];
          setStarData(newStarMessage);
        }
      } else if (sessionMetaData?.actionData?.action === "answer") {
        let message = sessionMetaData.actionData;
        let newStarMessage = [...starData];
        const index = newStarMessage.findIndex(
          ({ sender, senderUserId, time }) =>
            sender === message?.sender &&
            senderUserId === message?.senderUserId &&
            time === message?.time
        );

        if (index >= 0) {
          if (message?.type === "Add") {
            newStarMessage[index]["actionValue"] = 2;
          } else if (message?.type === "Remove") {
            newStarMessage[index]["actionValue"] = 1;
          }
          setStarData(newStarMessage);
        }
      } else if (sessionMetaData?.actionData?.action === "like") {
        let message = sessionMetaData.actionData;
        let newLikeMessage = [...likeData];
        newLikeMessage = [...newLikeMessage];
        const index = newLikeMessage.findIndex(
          ({ sender, senderUserId, time }) =>
            sender === message?.sender &&
            senderUserId === message?.senderUserId &&
            time === message?.time
        );
        if (message?.type === "Add") {
          if (index === -1) {
            newLikeMessage = [
              ...newLikeMessage,
              {
                sender: message?.sender,
                senderUserId: message?.senderUserId,
                time: message?.time,
                user: [message?.localPeerId],
              },
            ];
          } else {
            let likeUser = newLikeMessage?.[index]?.user || [];
            const likeIndex = likeUser.findIndex(
              u => u === message?.localPeerId
            );
            if (likeIndex === -1) {
              likeUser = [...likeUser, message?.localPeerId];
            } else {
              likeUser = [
                ...likeUser.slice(0, likeIndex),
                ...likeUser.slice(likeIndex + 1),
              ];
            }
            if (likeUser?.length > 0) {
              newLikeMessage[index] = {
                ...newLikeMessage?.[index],
                user: likeUser,
              };
            } else {
              newLikeMessage = [
                ...newLikeMessage.slice(0, index),
                ...newLikeMessage.slice(index + 1),
              ];
            }
          }
          setLikeData(newLikeMessage);
        } else if (message?.type === "Remove") {
          if (index >= 0) {
            let likeUser = newLikeMessage?.[index]?.user || [];
            const likeIndex = likeUser.findIndex(
              u => u === message?.localPeerId
            );
            if (likeIndex >= 0) {
              likeUser = [
                ...likeUser.slice(0, likeIndex),
                ...likeUser.slice(likeIndex + 1),
              ];
            }
            if (likeUser?.length > 0) {
              newLikeMessage[index] = {
                ...newLikeMessage?.[index],
                user: likeUser,
              };
            } else {
              newLikeMessage = [
                ...newLikeMessage.slice(0, index),
                ...newLikeMessage.slice(index + 1),
              ];
            }
            setLikeData(newLikeMessage);
          }
        }
      } else if (sessionMetaData?.actionData?.action === "delete") {
        let message = sessionMetaData.actionData;
        let newDeleteMessage = [...deleteMsgData];
        const index = newDeleteMessage.findIndex(
          ({ sender, senderUserId, time }) =>
            sender === message?.sender &&
            senderUserId === message?.senderUserId &&
            time === message?.time
        );
        if (index === -1) {
          newDeleteMessage = [
            ...newDeleteMessage,
            {
              sender: message?.sender,
              senderUserId: message?.senderUserId,
              time: message?.time,
            },
          ];
          setDeleteMsgData(newDeleteMessage);
        }
      }
    }
  }, [sessionMetaData]); //eslint-disable-line

  if (!isConnectedToRoom) {
    return <FullPageProgress />;
  }

  return (
    <Flex css={{ size: "100%" }} direction="column">
      {!isHeadless && (
        <Box css={{ h: "$18", "@md": { h: "$17" } }} data-testid="header">
          <Header />
        </Box>
      )}
      <Box
        css={{
          w: "100%",
          flex: "1 1 0",
          minHeight: 0,
        }}
        data-testid="conferencing"
      >
        <ConferenceMainView
          starData={starData}
          likeData={likeData}
          deleteMsgData={deleteMsgData}
          hasWidget={hasWidget}
        />
      </Box>
      {!isHeadless && (
        <Box css={{ flexShrink: 0, minHeight: "$24" }} data-testid="footer">
          <Footer hasWidget={hasWidget} hasChatQA={hasChatQA} />
        </Box>
      )}
      <RoleChangeRequestModal />
    </Flex>
  );
};

export default Conference;
