import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useMedia } from "react-use";
import {
  HMSPlaylistType,
  selectIsAllowedToPublish,
  useHMSStore,
  useScreenShare,
} from "@100mslive/react-sdk";
import { MusicIcon } from "@100mslive/react-icons";
import {
  config as cssConfig,
  Flex,
  Footer as AppFooter,
  Tooltip,
} from "@100mslive/roomkit-react";
import { Playlist } from "../../components/Playlist/Playlist";
import IconButton from "../../IconButton";
import { AudioVideoToggle } from "../AudioVideoToggle";
import { EmojiReaction } from "../EmojiReaction";
import { LeaveRoom } from "../LeaveRoom";
import MetaActions from "../MetaActions";
import { EmbedUrlModal, EmbedUrlToggle } from "../MoreSettings/EmbedUrl";
import { MoreSettings } from "../MoreSettings/MoreSettings";
import { PIP } from "../PIP";
import { ScreenshareToggle } from "../ScreenShare";
import { ScreenShareHintModal } from "../ScreenshareHintModal";
import StageOffOnToggle from "../StageOffOnToggle";
import { VBToggle } from "../VirtualBackground/VBToggle";
import { ChatToggle } from "./ChatToggle";
import { ToggleWidgets } from "./ToggleWidgets";
import { WidgetToggle } from "./WidgetToggle";
import { NoiseSuppression } from "../../plugins/NoiseSuppression";
//import { VirtualBackground } from "../../plugins/VirtualBackground/VirtualBackground";
import { ToggleWhiteboard } from "../../plugins/whiteboard";
import { isScreenshareSupported } from "../../common/utils";
import { FeatureFlags } from "../../services/FeatureFlags";

const TranscriptionButton = React.lazy(() =>
  import("../../plugins/transcription")
);

const ScreenshareAudio = () => {
  const {
    amIScreenSharing,
    screenShareVideoTrackId: video,
    screenShareAudioTrackId: audio,
    toggleScreenShare,
  } = useScreenShare();
  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  const isAudioScreenshare = amIScreenSharing && !video && !!audio;
  const [showModal, setShowModal] = useState(false);
  if (!isAllowedToPublish.screen || !isScreenshareSupported()) {
    return null;
  }
  return (
    <Fragment>
      <Tooltip
        title={`${!isAudioScreenshare ? "Start" : "Stop"} audio sharing`}
        key="shareAudio"
      >
        <IconButton
          active={!isAudioScreenshare}
          onClick={() => {
            if (amIScreenSharing) {
              toggleScreenShare();
            } else {
              setShowModal(true);
            }
          }}
          data-testid="screenshare_audio"
        >
          <MusicIcon />
        </IconButton>
      </Tooltip>
      {showModal && (
        <ScreenShareHintModal onClose={() => setShowModal(false)} />
      )}
    </Fragment>
  );
};

export const ConferencingFooter = props => {
  const { hasWidget, hasChatQA } = props;
  const { role } = useParams();
  const [showOpenUrl, setShowOpenUrl] = useState(false);
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <>
      <AppFooter.Root className="preview_tile_footer" translate="no">
        <AppFooter.Left className="preview_tile_footer_col">
          <ToggleWidgets />
          <ScreenshareAudio />
          {role && role !== "attendee" ? (
            <>
              <Playlist type={HMSPlaylistType.audio} />
              <Playlist type={HMSPlaylistType.video} />
            </>
          ) : null}
          {FeatureFlags.enableWhiteboard ? <ToggleWhiteboard /> : null}

          <NoiseSuppression />
          {FeatureFlags.enableTranscription && <TranscriptionButton />}
          <Flex
            align="center"
            css={{
              display: "none",
              "@md": {
                display: "flex",
              },
            }}
          >
            <MetaActions isMobile={isMobile} />
            {hasWidget && isMobile ? (
              <WidgetToggle hasWidget={hasWidget} />
            ) : null}
          </Flex>
        </AppFooter.Left>
        <AppFooter.Center className="preview_tile_footer_col">
          <StageOffOnToggle />
          <AudioVideoToggle />{" "}
          {!isMobile && FeatureFlags.enableVirtualBackground ? (
            <VBToggle />
          ) : null}
          <ScreenshareToggle />
          <EmbedUrlToggle
            showOpenUrl={showOpenUrl}
            setShowOpenUrl={setShowOpenUrl}
          />
          <PIP />
          <MoreSettings />
          <LeaveRoom />
          <Flex
            align="center"
            css={{ display: "none", "@md": { display: "flex" } }}
          >
            {hasChatQA && <ChatToggle />}
          </Flex>
        </AppFooter.Center>
        <AppFooter.Right className="preview_tile_footer_col">
          {FeatureFlags.enableEmojiReaction ? <EmojiReaction /> : null}
          <MetaActions />
          {hasChatQA && <ChatToggle />}
          {hasWidget && !isMobile ? (
            <WidgetToggle hasWidget={hasWidget} />
          ) : null}
        </AppFooter.Right>
      </AppFooter.Root>
      {showOpenUrl && <EmbedUrlModal onOpenChange={setShowOpenUrl} />}
    </>
  );
};
