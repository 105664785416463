import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { selectIsAllowedToPublish, useHMSStore } from "@100mslive/react-sdk";
import { ViewIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  Dialog,
  Dropdown,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { Edit, UploadFIle } from "../../icons";
import {
  DialogContent,
  DialogInput,
  DialogRow,
} from "../../primitives/DialogContent";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { useSetAppDataByKey } from "../AppData/useUISettings";
import { SESSION_AUTH } from "../../common/auth";
import { EVENT_SERVICES } from "../../services";
import { APP_DATA, PlatfomUrl } from "../../common/constants";
export const EmbedUrl = ({ setShowOpenUrl }) => {
  if (!window.CropTarget) {
    return null;
  }

  return (
    <Dropdown.Item
      onClick={() => {
        setShowOpenUrl(true);
      }}
      data-testid="embed_url_btn"
    >
      <ViewIcon />
      <Text variant="sm" css={{ ml: "$4" }}>
        Embed URL
      </Text>
    </Dropdown.Item>
  );
};
export const EmbedUrlToggle = ({ showOpenUrl, setShowOpenUrl }) => {
  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  if (!window.CropTarget || !isAllowedToPublish?.screen) {
    return null;
  }

  return (
    <Tooltip title="Embeded URL">
      <IconButton
        disabled={showOpenUrl}
        active={!showOpenUrl}
        onClick={() => {
          setShowOpenUrl(true);
        }}
        data-testid="embed_url_toggle"
      >
        <ViewIcon />
      </IconButton>
    </Tooltip>
  );
};

export function EmbedUrlModal({ onOpenChange }) {
  const { isUserSession } = SESSION_AUTH();
  const [embedConfig, setEmbedConfig] = useSetAppDataByKey(
    APP_DATA.embedConfig
  );
  const [url, setUrl] = useState(embedConfig?.url || "");

  const isAnythingEmbedded = !!embedConfig?.url;
  const isModifying = isAnythingEmbedded && url && url !== embedConfig.url;
  const [embedFile, setEmbedFile] = useState("");
  const navigate = useNavigate();
  const [fileProgress, setFileProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const onSelectEmbedFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setEmbedFile("");
      return;
    }
    setEmbedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (embedFile) {
      embedFileUpload();
    }
    return () => null;
  }, [embedFile]);

  const embedFileUpload = async () => {
    if (!embedFile) {
      return false;
    }
    setLoading(true);
    setFileProgress(0);

    try {
      const parma = new FormData();
      parma.append("userId", isUserSession?.userId);
      parma.append("org", isUserSession?.org?.id);
      parma.append("document", embedFile, embedFile.name);

      const data = await EVENT_SERVICES.presenterdocumentsUpload(
        parma,
        onUploadProgress
      );
      if (data && data.code === 200) {
        setEmbedFile("");
        ToastManager.addToast({ title: data.message });
        if (data?.url) {
          setUrl(data?.url + "#toolbar=0");
          try {
            await navigator.clipboard.writeText(data?.url + "#toolbar=0");
            ToastManager.addToast({ title: "URL Copied" });
          } catch (err) {
            //console.error("Failed to copy: ", err);
          }
        }
      } else if (data.code === 600) {
        navigate(0);
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }

    setLoading(false);
  };
  const onUploadProgress = data => {
    setFileProgress(Math.round((100 * data.loaded) / data.total));
  };

  return (
    <Dialog.Root defaultOpen onOpenChange={onOpenChange}>
      <DialogContent title="Embed URL" Icon={ViewIcon}>
        {loading ? (
          <Box
            style={{
              width: "100%",
              height: "100vh",
              position: "fixed",
              left: "0px",
              top: "0px",
              zIndex: 999999999,
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {fileProgress && fileProgress < 100 ? (
              <div className="eventsUploadProgress">
                <CircularProgress
                  className="eventsUploadProgress-circle"
                  variant="determinate"
                  value={fileProgress}
                  size={100}
                />
                <div className="eventsUploadProgress-text">{`${Math.round(
                  fileProgress
                )}%`}</div>
              </div>
            ) : (
              <FullPageProgress />
            )}
          </Box>
        ) : null}
        <DialogInput
          title="URL"
          value={url}
          onChange={setUrl}
          placeholder={PlatfomUrl}
          type="url"
        />

        <div className="custom-form-group preview-embeded-fileUpload">
          <div className="custom-form-group preview-embeded-fileUpload-or">
            OR
          </div>
          <label className="custom-form-file mt-10" translate="no">
            <input
              type="file"
              accept=".PDF"
              className="custom-form-file-input"
              onChange={onSelectEmbedFile}
            />

            {embedFile ? (
              <>
                <Edit />{" "}
                <span className="preview-embeded-fileUpload-name">
                  {embedFile?.name}
                </span>
              </>
            ) : (
              <>
                <UploadFIle />{" "}
                <span
                  className="preview-embeded-fileUpload-name"
                  translate="yes"
                >
                  Upload PDF
                </span>
              </>
            )}
          </label>
        </div>
        <DialogRow>
          <Text>
            Embed a url and share with everyone in the room. Ensure that you're
            sharing the current tab when the prompt opens. Note that not all
            websites support being embedded.
          </Text>
        </DialogRow>
        <DialogRow justify="end">
          {isAnythingEmbedded ? (
            <>
              <Button
                variant="primary"
                type="submit"
                disabled={!isModifying}
                onClick={() => {
                  setEmbedConfig({
                    url,
                    shareScreen: embedConfig.shareScreen,
                  });
                  onOpenChange(false);
                }}
                data-testid="embed_url_btn"
                css={{ mr: "$4" }}
              >
                Update Embed
              </Button>
              <Button
                variant="danger"
                type="submit"
                onClick={() => {
                  setEmbedConfig({ url: "" });
                  onOpenChange(false);
                }}
                data-testid="embed_url_btn"
              >
                Stop Embed
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="primary"
                type="submit"
                disabled={!url.trim()}
                onClick={() => {
                  setEmbedConfig({ url });
                  onOpenChange(false);
                }}
                data-testid="embed_url_btn"
                css={{ mr: "$4" }}
              >
                Present to Self
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!url.trim()}
                onClick={() => {
                  setEmbedConfig({ url, shareScreen: true });
                  onOpenChange(false);
                }}
                data-testid="embed_url_btn"
              >
                Present to All
              </Button>
            </>
          )}
        </DialogRow>
      </DialogContent>
    </Dialog.Root>
  );
}
