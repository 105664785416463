import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Box, Flex } from "@100mslive/roomkit-react";
import dummyContent from "../../../assets/images/report/investor-poster.png";
import { useGlobalValue } from "../../../GlobalContext";
import { InvestorChart } from "../../Chart/InvestorChart";
import { SentimentChart } from "../../Chart/SentimentChart";
import { ShareHolderChart } from "../../Chart/ShareHolderChart";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import InvestorExport from "./InvestorExport";
import InvestorTable from "./InvestorTable";
import { SESSION_AUTH } from "../../../common/auth";
import { INVESTOR_SERVICES } from "../../../services";

const InvestorReport = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { setLoginUserVerify } = useGlobalValue();
  const [dataFetched, setDataFetched] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [totalRegistration, setTotalRegistration] = useState(0);
  const [totalAttendees, setTotalAttendees] = useState(0);
  const [totalChequeSize, settTotalChequeSize] = useState(0);
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [totalRequestedMeeting, settotalRequestedMeeting] = useState(0);
  const [investorChartData, setInvestorChartData] = useState("");
  const [shareHolderChartData, setShareHolderChartData] = useState("");
  const [sentimentChartData, setSentimentChartData] = useState("");
  const [totalInvited, setTotalInvited] = useState(0);

  const [totalRegisterViews, setTotalRegisterViews] = useState(0);
  const [totalAttendeeViews, setTotalAttendeeViews] = useState(0);
  const [totalReplayViews, setTotalReplayViews] = useState(0);
  const [totalOndemandViews, setTotalOndemandViews] = useState(0);

  const [registrants, setRegistrants] = useState(0);
  const [attendees, setAttendees] = useState(0);
  const [getreplays, setGetreplays] = useState(0);
  const [ondemands, setOndemands] = useState(0);

  const [loading, setLoading] = useState(true);
  const [isSubscription, setIsSubscription] = useState("");

  useEffect(() => {
    if (!isUserSession) {
      setUserData("");
      return navigate("/");
    } else if (!isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    } else {
      setUserData(isUserSession);
    }
    return () => null;
  }, [isUserSession, navigate, setLoginUserVerify]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoading(true);
    try {
      INVESTOR_SERVICES.investor({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setTotalRows(data?.data?.totalRows || 0);
            setTotalRegistration(data?.data?.totalRegister || 0);
            settTotalChequeSize(data.data?.totalChequeSize || 0);
            setTotalAttendees(data.data?.totalAttends || 0);
            setTotalInvestedAmount(data.data?.totalInvestedAmount || 0);
            settotalRequestedMeeting(data.data?.totalMeetingReq || 0);
            setTotalEvents(data.data?.totalEvent || 0);
            setIsSubscription(data.data?.isSubscription);
            setTotalInvited(data.data?.totalInvited || 0);
            if (data.data?.graph) {
              setInvestorChartData(data.data?.graph?.investor);
              setShareHolderChartData(data.data?.graph?.shareHolder);
              setSentimentChartData(data.data?.graph?.sentiment);
            }
            setLoading(false);

            setTotalRegisterViews(data.data?.totalRegisterViews || 0);
            setTotalAttendeeViews(data.data?.totalAttendeeViews || 0);
            setTotalReplayViews(data.data?.totalReplayViews || 0);
            setTotalOndemandViews(data.data?.totalOndemandViews || 0);

            setRegistrants(data.data?.registrants || 0);
            setAttendees(data.data?.attendees || 0);
            setGetreplays(data.data?.getreplays || 0);
            setOndemands(data.data?.ondemands || 0);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            navigate("/dashboard");
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, navigate]);

  const goInvitedUser = () => {
    if (isSubscription) {
      navigate("/investors/invited");
    } else {
      navigate("/account/setting/billing");
    }
  };

  return (
    <React.Fragment>
      <Flex className="events6ix-layout-page-top">
        <h1 className="events6ix-layout-page-top-heading events6ix-layout-page-top-heading-line">
          Investors Report
        </h1>
        {totalRows ? <InvestorExport isSubscription={isSubscription} /> : null}
      </Flex>
      {loading ? (
        <FullPageProgress fixed={true} />
      ) : (
        <Box className="relationship-container">
          {totalRows ? (
            <React.Fragment>
              <Flex className="relationship-container-inner">
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Total Registrants </label>
                    <span>{totalRegistration}</span>
                  </Flex>
                </Box>
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Total Attendees </label>
                    <span>{totalAttendees}</span>
                  </Flex>
                </Box>
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Cheque Size (Total) </label>

                    <NumericFormat
                      value={totalChequeSize}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$"
                      renderText={(value, props) => (
                        <span {...props}>{value}</span>
                      )}
                    />
                  </Flex>
                </Box>
                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Invested Amount (Total) </label>

                    <NumericFormat
                      value={totalInvestedAmount}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$"
                      renderText={(value, props) => (
                        <span {...props}>{value}</span>
                      )}
                    />
                  </Flex>
                </Box>

                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Meeting Requests (Total) </label>
                    <span>{totalRequestedMeeting}</span>
                  </Flex>
                </Box>

                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1" }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Total Hosted Events </label>
                    <span>{totalEvents}</span>
                  </Flex>
                </Box>

                <Box
                  className="relationship-container-inner-content"
                  css={{ position: "relative", r: "$1", cursor: "pointer" }}
                  onClick={() => {
                    if (totalInvited) goInvitedUser();
                  }}
                >
                  <Flex
                    direction="column"
                    className="relationship-container-inner-content-box"
                  >
                    <label> Invited (Total) </label>
                    <span>{totalInvited}</span>
                  </Flex>
                </Box>
                {registrants ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> Registers </label>
                      <span>{registrants}</span>
                    </Flex>
                  </Box>
                ) : null}
                {totalRegisterViews ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> Total Register Page Views </label>
                      <span>{totalRegisterViews}</span>
                    </Flex>
                  </Box>
                ) : null}
                {attendees ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> Attendees </label>
                      <span>{attendees}</span>
                    </Flex>
                  </Box>
                ) : null}
                {totalAttendeeViews ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> Total Attendee Page Views </label>
                      <span>{totalAttendeeViews}</span>
                    </Flex>
                  </Box>
                ) : null}

                {getreplays ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> Get the Replay</label>
                      <span>{getreplays}</span>
                    </Flex>
                  </Box>
                ) : null}

                {totalReplayViews ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> Total Replay Page Views </label>
                      <span>{totalReplayViews}</span>
                    </Flex>
                  </Box>
                ) : null}

                {ondemands ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> On-Demands</label>
                      <span>{ondemands}</span>
                    </Flex>
                  </Box>
                ) : null}
                {totalOndemandViews ? (
                  <Box
                    className="relationship-container-inner-content"
                    css={{ position: "relative", r: "$1" }}
                  >
                    <Flex
                      direction="column"
                      className="relationship-container-inner-content-box"
                    >
                      <label> Total On-Demand Page Views </label>
                      <span>{totalOndemandViews}</span>
                    </Flex>
                  </Box>
                ) : null}
              </Flex>
              <Flex className="relationship-container-inner-charts">
                {investorChartData ? (
                  <Box className="relationship-container-inner-charts-col">
                    <InvestorChart
                      chartData={investorChartData}
                      report={true}
                    />
                  </Box>
                ) : null}

                {shareHolderChartData ? (
                  <Box className="relationship-container-inner-charts-col">
                    <ShareHolderChart
                      chartData={shareHolderChartData}
                      report={true}
                    />
                  </Box>
                ) : null}
                {sentimentChartData ? (
                  <Box className="relationship-container-inner-charts-col">
                    <SentimentChart
                      chartData={sentimentChartData}
                      report={true}
                    />
                  </Box>
                ) : null}
              </Flex>

              <InvestorTable
                totalRows={totalRows}
                isSubscription={isSubscription}
              />
            </React.Fragment>
          ) : (
            <img src={dummyContent} alt="report data" />
          )}
          {!isSubscription || !totalRows ? (
            <div className="relationship-upgrade">
              <div className="relationship-upgrade-title">
                What is an investors report?
              </div>
              <div className="relationship-upgrade-text">
                Analyze, synchronize and download <br />
                all of your investors data from one place.
              </div>
              {!isSubscription ? (
                <Button
                  component={Link}
                  to="/account/setting/billing"
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                >
                  UPGRADE TO GET ACCESS
                </Button>
              ) : (
                <Button
                  component={Link}
                  to="/create"
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                >
                  Host an event
                </Button>
              )}
            </div>
          ) : null}
        </Box>
      )}
    </React.Fragment>
  );
};
export default InvestorReport;
