import React, { createContext, useContext, useState } from "react";
import { useMedia } from "react-use";
import { config as cssConfig } from "@100mslive/roomkit-react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [loggedinNotified, setLoggedinNotified] = useState("");
  const [isUserReinitialize, setIsUserReinitialize] = useState("");
  const [loginUserVerify, setLoginUserVerify] = useState(false);

  const [openSidebar, setOpenSidebar] = useState(
    useMedia(cssConfig.media.md) ? false : true
  );

  return (
    <GlobalContext.Provider
      value={{
        loggedinNotified,
        setLoggedinNotified,
        isUserReinitialize,
        setIsUserReinitialize,
        openSidebar,
        setOpenSidebar,
        loginUserVerify,
        setLoginUserVerify,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalValue = () => useContext(GlobalContext);
