import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { Box } from "@100mslive/roomkit-react";
import FullPageProgress from "../../components/FullPageProgress";
import { ToastManager } from "../../components/Toast/ToastManager";
import { ReplayViewer } from "../Event/ReplayViewer";
import { DashboardEventDelete } from "./DashboardEventDelete";
import { DashboardEventDownload } from "./DashboardEventDownload";
import { DashboardEventList } from "./DashboardEventList";
import { DashboardEventReplay } from "./DashboardEventReplay";
import { SESSION_AUTH } from "../../common/auth";
import { EVENT_SERVICES } from "../../services";
import { GET_PUBLIC_SESSION, SET_PUBLIC_SESSION } from "../../common/constants";
import "./style.scss";
export const Dashboard = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { listType } = useParams();
  const eventTabs = {
    upcoming: "Upcoming",
    hosted: "Hosted",
    attended: "Attended",
  };
  const [ToggleState, setToggleState] = useState(
    eventTabs[listType] ? listType : "upcoming"
  );
  const [userData, setUserData] = useState(null);
  const [listingLoading, setListingLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const [deleteEventConfirm, setDeleteEventConfirm] = useState(false);
  const [downloadEventConfirm, setDownloadEventConfirm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [actionCard, setActionCard] = useState("");
  const [replayEventConfirm, setReplayEventConfirm] = useState(false);
  const [replayEvent, setReplayEvent] = useState(false);
  const [replayEventView, setReplayEventView] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState(() => {
    let v = GET_PUBLIC_SESSION("EU_D_TYPE");
    let vu = v === "organization" && listType !== "attended" ? v : "individual";
    SET_PUBLIC_SESSION("EU_D_TYPE", vu);
    return {
      type: vu,
      page: 1,
      limit: 20,
    };
  });
  const { ref: loadMoreItemsBox, inView: loadMoreItemsBoxVisible } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);

    try {
      setListingLoading(true);
      setActionCard("");
      if (filter?.page === 1) {
        setListData([]);
      }
      let parma = {
        userId: userData.userId,
        org: userData?.org?.id,
        page: filter?.page,
        limit: filter?.limit,
        type: filter?.type,
      };
      if (ToggleState === "hosted") {
        EVENT_SERVICES.hostEventList(parma)
          .then(data => {
            if (data.code === 200) {
              if (filter?.page === 1) {
                setListData(data.data);
              } else {
                setListData(old => [...old, ...data.data]);
              }

              setHasMore(data?.data?.length >= parma?.limit);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            } else {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(error => {
            ToastManager.addToast({ title: error.message });
          })
          .finally(() => {
            setListingLoading(false);
          });
      } else if (ToggleState === "upcoming") {
        EVENT_SERVICES.upcomingEventList(parma)
          .then(data => {
            if (data.code === 200) {
              if (filter?.page === 1) {
                setListData(data.data);
              } else {
                setListData(old => [...old, ...data.data]);
              }
              setHasMore(data?.data?.length >= parma?.limit);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            } else {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(error => {
            ToastManager.addToast({ title: error.message });
          })
          .finally(() => {
            setListingLoading(false);
          });
      } else if (ToggleState === "attended") {
        EVENT_SERVICES.attendedEventList(parma)
          .then(data => {
            if (data.code === 200) {
              if (filter?.page === 1) {
                setListData(data.data);
              } else {
                setListData(old => [...old, ...data.data]);
              }
              setHasMore(data?.data?.length >= parma?.limit);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            } else {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(error => {
            ToastManager.addToast({ title: error.message });
          })
          .finally(() => {
            setListingLoading(false);
          });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, filter, ToggleState, LOGOUT_USER_SESSION]);

  const resetFilter = () => {
    setFilter(old => ({
      ...old,
      page: 1,
      last: new Date(),
    }));
  };
  useEffect(() => {
    setDataFetched(false);
    return () => null;
  }, [ToggleState, filter]);

  const changeTabs = item => {
    if (item !== ToggleState && !listingLoading) {
      setToggleState(item);
      resetFilter();
    }
  };

  useEffect(() => {
    if (!loadMoreItemsBoxVisible) return;
    loadMoreContent();
  }, [loadMoreItemsBoxVisible]);

  const loadMoreContent = () => {
    setFilter(old => ({
      ...old,
      page: old.page + 1,
    }));
  };

  const selectReplayEvent = async event => {
    if (event) {
      try {
        setLoading(true);
        EVENT_SERVICES.getEventReplay({
          userId: userData?.userId,
          org: userData?.org?.id,
          id: event._id,
        })
          .then(data => {
            if (data.code === 200) {
              setReplayEvent(data?.data);
              setSelectedEvent(event);
              setReplayEventConfirm(true);
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            }
            if (data.message) {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(error => {
            ToastManager.addToast({ title: error.message });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        ToastManager.addToast({ title: error.message });
      }
    } else {
      setReplayEventConfirm(false);
      setSelectedEvent("");
    }
  };

  const selectDownloadEvent = event => {
    if (event) {
      setSelectedEvent(event);
      setDownloadEventConfirm(true);
    } else {
      setDownloadEventConfirm(false);
      setSelectedEvent("");
    }
  };
  const selectDeleteEvent = event => {
    setDeleteEventConfirm(true);
    setSelectedEvent(event);
  };
  const closeDeleteEvent = () => {
    setDeleteEventConfirm(false);
  };

  const deleteEvent = async () => {
    setDeleteEventConfirm(false);
    try {
      setLoading(true);
      EVENT_SERVICES.deleteEvent({
        userId: userData.userId,
        org: userData?.org?.id,
        id: selectedEvent._id,
      })
        .then(data => {
          if (data.code === 200) {
            resetFilter();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  };

  const CopyText = text => {
    navigator.clipboard.writeText(text);
    ToastManager.addToast({ title: "Meeting Link Copied!" });
  };
  const changeOnType = e => {
    let value = e.target.value;
    setFilter({
      ...filter,
      type: value,
    });
    SET_PUBLIC_SESSION("EU_D_TYPE", value);
  };

  return (
    <React.Fragment>
      {loading && <FullPageProgress zindex={999999999} fixed={true} />}

      <div className="product-launch-header">
        <div className="product-launch-header-type">
          <Select
            id="invite-role"
            className="custom-select"
            sx={{
              height: "42px",
              background: "transparent !important",
              width: "100%",
            }}
            value={filter?.type}
            onChange={changeOnType}
            displayEmpty
          >
            {ToggleState !== "attended" && (
              <MenuItem value="organization">Organization</MenuItem>
            )}
            <MenuItem value="individual">Individual</MenuItem>
          </Select>
        </div>
        <div className="product-launch-header-lineV"></div>
        <ul className="events6ix-tabs product-launch-header-tabs">
          {Object.keys(eventTabs).map((item, key) =>
            filter?.type === "organization" && item === "attended" ? null : (
              <Link
                to={`/dashboard/${item}`}
                key={key}
                className={`events6ix-tabs-item ${
                  item === ToggleState ? "active" : ""
                }`}
                onClick={e => {
                  if (listingLoading) {
                    e.preventDefault();
                  } else {
                    changeTabs(item);
                  }
                }}
              >
                {eventTabs[item]}
              </Link>
            )
          )}
        </ul>
      </div>
      <div className="new-event-page-tabs-data">
        <div className="product-launch-row">
          {listData.map((listItem, key) => {
            return (
              <DashboardEventList
                key={key}
                listItem={listItem}
                actionCard={actionCard}
                setActionCard={setActionCard}
                ToggleState={ToggleState}
                selectDownloadEvent={selectDownloadEvent}
                CopyText={CopyText}
                selectDeleteEvent={selectDeleteEvent}
                selectReplayEvent={selectReplayEvent}
                userData={userData}
              />
            );
          })}
          {listingLoading && (
            <Box className="ml-auto mr-auto">
              <FullPageProgress />
            </Box>
          )}
        </div>

        {hasMore && !listingLoading && (
          <div className="eventsLoadMoreProgrss" ref={loadMoreItemsBox}></div>
        )}
      </div>

      {userData?.isVerify && (
        <>
          {deleteEventConfirm && (
            <DashboardEventDelete
              deleteEventConfirm={deleteEventConfirm}
              closeDeleteEvent={closeDeleteEvent}
              selectedEvent={selectedEvent}
              deleteEvent={deleteEvent}
            />
          )}
          {downloadEventConfirm && selectedEvent && (
            <DashboardEventDownload
              selectDownloadEvent={selectDownloadEvent}
              selectedEvent={selectedEvent}
            />
          )}
          {replayEventConfirm && selectedEvent && !loading && (
            <DashboardEventReplay
              selectReplayEvent={selectReplayEvent}
              selectedEvent={selectedEvent}
              replayEventConfirm={replayEventConfirm}
              replayEvent={replayEvent}
              setReplayEventView={setReplayEventView}
            />
          )}
          {replayEventView && replayEvent && !loading && (
            <ReplayViewer
              replayData={{
                poster: replayEvent?.eventCover,
                replay: replayEvent?.replay,
                autoPlay: true,
                overlay: true,
              }}
              close={setReplayEventView}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
