import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link, useParams } from "react-router-dom";
import { Box } from "@100mslive/roomkit-react";
import FullPageProgress from "../../components/FullPageProgress";
import { ToastManager } from "../../components/Toast/ToastManager";
import ExploreFilter from "./ExploreFilter";
import ExploreInvestContent from "./ExploreInvestContent";
import ExploreList from "./ExploreList";
import ExploreStatics from "./ExploreStatics";
import { EXPLORE_SERVICES } from "../../services";
import "./style.scss";

const Explore = () => {
  const { ref: loadMoreItemsBox, inView: loadMoreItemsBoxVisible } = useInView({
    threshold: 0,
  });
  const { listType } = useParams();
  const eventTabs = {
    all: "All",
    upcoming: "Upcoming",
    "on-demand": "On Demand",
    "get-notified-of-replays": "Get Notified of Replays",
  };
  const [ToggleState, setToggleState] = useState(
    eventTabs?.[listType] ? listType : "upcoming"
  );
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const dataFetchedRef = useRef(false);
  //const loadMoreItemsVisible = useRef(null);

  const [listData, setListData] = useState([]);
  const limit = 20;
  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState({ page: 1, limit: limit });

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setLoading(true);
    if (filter?.page === 1) {
      setListData([]);
    }
    if (ToggleState === "all") {
      EXPLORE_SERVICES.allList(filter)
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
          dataFetchedRef.current = false;
        });
    } else if (ToggleState === "upcoming") {
      EXPLORE_SERVICES.upcomingList(filter)
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
          dataFetchedRef.current = false;
        });
    } else if (ToggleState === "on-demand") {
      EXPLORE_SERVICES.onDemandList(filter)
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
          dataFetchedRef.current = false;
        });
    } else if (ToggleState === "get-notified-of-replays") {
      EXPLORE_SERVICES.notifiedList(filter)
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
          dataFetchedRef.current = false;
        });
    } else if (ToggleState === "live") {
      EXPLORE_SERVICES.liveList(filter)
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
          dataFetchedRef.current = false;
        });
    }
  }, [filter, ToggleState]);

  const updateData = data => {
    if (data && data?.length > 0) {
      setListData(old => [...old, ...data]);
      setHasMore(data.length === limit);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (!loadMoreItemsBoxVisible) return;
    loadMoreContent();
  }, [loadMoreItemsBoxVisible]);

  const loadMoreContent = () => {
    setFilter(old => ({
      ...old,
      page: old.page + 1,
    }));
  };

  const changeTabs = item => {
    if (item !== ToggleState && !loading) {
      setToggleState(item);
      setHasMore(false);
      setFilter(old => ({
        ...old,
        page: 1,
      }));
    }
  };
  const onSearchSubmit = () => {
    setFilter(old => ({
      ...old,
      page: 1,
      search: textSearch,
    }));
  };
  const onSearchReset = () => {
    setTextSearch("");
    setFilter(old => ({
      ...old,
      page: 1,
      search: "",
    }));
  };
  return (
    <React.Fragment>
      <div className="events6ix-layout-page-middle">
        <ExploreStatics />
        <ExploreInvestContent />
        <ExploreFilter
          onSubmit={onSearchSubmit}
          onReset={onSearchReset}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
        />
        <div className="event-page-explore-content">
          <ul className="events6ix-tabs">
            {Object.keys(eventTabs).map((item, key) => (
              <Link
                to={`/explore/${item}`}
                key={key}
                className={`events6ix-tabs-item ${
                  item === ToggleState ? "active" : ""
                }`}
                onClick={e => {
                  if (loading) {
                    e.preventDefault();
                  } else {
                    changeTabs(item);
                  }
                }}
              >
                {eventTabs[item]}
              </Link>
            ))}
          </ul>
          <div className="new-event-page-tabs-data">
            <ExploreList
              loading={loading}
              listData={listData}
              ToggleState={ToggleState}
            />
            {loading ? (
              <Box>
                <FullPageProgress />
              </Box>
            ) : hasMore ? (
              <div
                className="eventsLoadMoreProgrss"
                ref={loadMoreItemsBox}
              ></div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Explore;
